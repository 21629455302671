import { useCartContext } from 'contexts/cart/cart_context';
import React, { useEffect, useState } from 'react'

type CartCounterAmountToggleProps = {
    id: string;
    label: string;
    inforAndBrand: { [key: string]: any };
    parentClassName: string,
    currencySign: any,
    disabled: boolean,
    count?: number,
    placeId:string,
    onClick?: (id: string, count: number, inforAndBrand: any, currencySign: any) => void;
};

export const CartCounterAmountToggle: React.FC<CartCounterAmountToggleProps> = ({
    id,
    label,
    inforAndBrand,
    parentClassName,
    currencySign,
    disabled,
    count,
    placeId,
    onClick,
}) => {
    const { addToCart } = useCartContext();
    const [counterAmount, setCounterAmount] = useState(0);

    useEffect(() => {
        setCounterAmount(count || counterAmount)
    }, [count]);
    const handleIncrease = () => {
        const newCounterAmount = counterAmount + 1;
        setCounterAmount(newCounterAmount);
        addToCart(id, newCounterAmount, inforAndBrand, currencySign,placeId);
        if (onClick) onClick(id, newCounterAmount, inforAndBrand, currencySign);
    };

    const handleDecrease = () => {
        if (counterAmount >= 0) {
            const newCounterAmount = counterAmount - 1;
            setCounterAmount(newCounterAmount);
            addToCart(id, newCounterAmount, inforAndBrand, currencySign,placeId);
            if (onClick) onClick(id, newCounterAmount, inforAndBrand, currencySign);
        }
    };

    return (
        <div>
            {counterAmount === 0 && <button
                disabled={disabled}
                style={{
                    color: inforAndBrand?.textColor || "#000",
                    background: inforAndBrand?.backgroundColor || '#fff'
                }}
                className={`plus-min-btn plus-btn-only absolute   bg-white ${parentClassName == 'parent-pill-menu' ? 'bottom-4 right-4' : 'right-0 bottom-0 shadow-InputBorder rounded-full'}`} type="button"
                onClick={handleIncrease}
            >+</button>}

            {counterAmount > 0 && <div className={`absolute   ${parentClassName == 'parent-pill-menu' ? 'bottom-4 right-4' : 'right-0 bottom-0 shadow-InputBorder rounded-full'}`}>
                <div className="input-group flex flex-row">
                    <div className={`${parentClassName == 'parent-pill-menu' ? '' : ' rounded-full'}`}>
                        <button
                            disabled={disabled}
                            style={{
                                color: inforAndBrand?.textColor || "#000",
                                background: inforAndBrand?.backgroundColor || '#fff'
                            }}
                            className="plus-min-btn plus-btn-num bg-white" type="button" onClick={handleDecrease}>-</button>
                    </div>
                    <input
                        style={{
                            color: inforAndBrand?.textColor || "#000",
                            background: inforAndBrand?.backgroundColor || '#fff'
                        }}
                        type="text" className="w-10 text-center" value={counterAmount} readOnly />
                    <div className={`${parentClassName == 'parent-pill-menu' ? '' : ' rounded-full'}`}>
                        <button
                            disabled={disabled}
                            style={{
                                color: inforAndBrand?.textColor || "#000",
                                background: inforAndBrand?.backgroundColor || '#fff'
                            }} className="plus-min-btn min-btn-num bg-white" type="button" onClick={handleIncrease}>+</button>
                    </div>
                </div>
            </div>}
        </div>
    )
}
