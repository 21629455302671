import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PRICING_TYPE } from "constant/status.constant";

export interface PlaceCategory {
  id: string;
  name: string;
  createdDate?: Date;
  menuItems: MenuItem[];
  placeId?: string;
  status: number;
  expanded?: boolean;
  description?: string;
}

export interface OptionGroup {
  id?: string;
  name: string;
  createdDate?: Date;
  options: Option[];
  placeId?: string;
  optionRule: OptionRule;
}

export interface OptionRule {
  min: number;
  max: number;
  isRequired: boolean;
  selectionMode: number;
}

export interface Option {
  id?: string;
  name: string;
  createdDate?: Date;
  price: number;
  placeId?: string;
  status: number;
  position: number;
}

export interface MenuItem {
  id: string | number;
  name: string;
  price: number;
  priceAfterDiscount: number;
  pricingType: number;
  pricingText?: string;
  status: string | number;
  createdDate?: Date;
  description?: string;
  image?: string;
  categoryId?: string;
  currency?: string;
}

export interface Field {
  field: string;
  value: string | number | boolean;
}

export interface PlaceState {
  name: string;
  categories: PlaceCategory[];
  optionGroups: OptionGroup[];
  id?: string;
  url: string;
  createdDate?: Date | null;
  qrCode: string;
  shortUrl: string;
  menuItemCount: number | string | null;
  categoryCount: number | string | null;
  currency?: string;
  languages?: any;
  categoriesOriginal: PlaceCategory[];
  phoneNumber: string;
}

const initialState: PlaceState = {
  name: "",
  categories: [],
  optionGroups: [],
  url: "",
  createdDate: null,
  qrCode: "",
  shortUrl: "",
  menuItemCount: null,
  categoryCount: null,
  categoriesOriginal: [],
  phoneNumber: ""
};

export const PlaceSlice = createSlice({
  name: "place",
  initialState,
  reducers: {
    setPlaces: (state, action: PayloadAction<{
      place: PlaceState
    }>) => {
    },
    setOptionGroups: (state, action: PayloadAction<{
      newOptionGroups: OptionGroup[]
    }>) => {
      const prevOptionGroups = JSON.parse(JSON.stringify(state?.optionGroups));
      const newOptionGroups = action.payload.newOptionGroups?.map(
        (item, index) => {
          return {
            ...item,
            expanded: prevOptionGroups[index]?.expanded || false,
          };
        }
      );
      state.optionGroups = newOptionGroups;
    },
    setCategories: (state, action: PayloadAction<{
      newCategories: PlaceCategory[],
      categories: PlaceCategory[]
    }>) => {
      // Current state's categories have been cleared by searchItem method
      const prevCategories = action.payload.categories ?? JSON.parse(JSON.stringify(state?.categories));
      const newCategories = action.payload.newCategories?.map((item, index) => {
        return {
          ...item,
          expanded: prevCategories.find((x: any)=> x.id === item.id)?.expanded || false,
        };
      });
      state.categories = newCategories;
    },
    setCategoriesOriginal: (state, action: PayloadAction<{
      newCategories: PlaceCategory[]
    }>) => {
      state.categoriesOriginal = action.payload.newCategories;
    },
    addItemToCategory: (
      state,
      action: PayloadAction<{ index: number; count: number }>
    ) => {
      const newItem: MenuItem = {
        id: "",
        name: "New item",
        price: 0,
        priceAfterDiscount: 0,
        status: 2,
        pricingType: PRICING_TYPE["Fixed Price"]
      };
      let categories = state.categories;
      categories = categories.map((item, index) => {
        if (action.payload.index === index) {
          const menuItems = item?.menuItems || [];
          let localCount = action.payload.count;
          while (localCount) {
            menuItems.push(newItem);
            --localCount;
          }
          return {
            ...item,
            expanded: true,
            menuItems,
          };
        }
        return item;
      });
      state.categories = categories;
    },
    changePositionCategory: (
      state,
      action: PayloadAction<{ categories: any }>
    ) => {
      state.categories = action.payload.categories;
    },
    changePositionItem: (state, action: PayloadAction<{ categories: any }>) => {
      state.categories = action.payload.categories;
    },
    changePositionMenuItems: (state, action: PayloadAction<{ categoryId: string, menuItems: any }>) => {
      const categoryIndex = state.categories.findIndex(c => c.id === action.payload.categoryId);
      if (categoryIndex >= 0) {
        state.categories[categoryIndex].menuItems = [...action.payload.menuItems];
      }
    },
    updateCategoryField: (
      state,
      action: PayloadAction<{
        index: number;
        values: Field;
      }>
    ) => {
      let categories = state.categories;
      categories = categories.map((item: any, index) => {
        if (index === action.payload.index) {
          item[action.payload.values.field] = action.payload.values.value;
        }
        return item;
      });
      state.categories = categories;
    },
    updateItemField: (
      state,
      action: PayloadAction<{
        categoryIndex: number;
        index: number;
        values: Field;
      }>
    ) => {
      let categories = state.categories;
      categories = categories.map((item, index) => {
        if (action.payload.categoryIndex === index) {
          let menuItems: MenuItem[] = item.menuItems;
          menuItems = menuItems?.map((menu: any, indexMenu) => {
            if (indexMenu == action.payload.index) {
              menu[action.payload.values.field] = action.payload.values.value;
            }
            return menu;
          });
          return {
            ...item,
            menuItems,
          };
        }
        return item;
      });
      state.categories = categories;
    },
    searchItems: (
      state,
      action: PayloadAction<{
        newCategories: PlaceCategory[];
      }>
    ) => {
      state.categories = action.payload.newCategories;
    },
    deleteItemState: (state, action: PayloadAction<{ id: string }>) => {
      let categories = state.categories;
      categories = categories.map((item, index) => {
        return {
          ...item,
          menuItems: item?.menuItems?.filter(
            (menu) => menu?.id != action.payload.id
          ),
        };
      });
      state.categories = categories;
    },
    deleteCategoryState: (state, action: PayloadAction<{ id: string }>) => {
      let categories = state.categories;
      categories = categories.filter(
        (item, index) => item?.id != action?.payload?.id
      );
      state.categories = categories;
    },
    deleteOptionGroupState: (state, action: PayloadAction<{ id: string }>) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.filter(
        (item, index) => item?.id != action?.payload?.id
      );
      state.optionGroups = optionGroups;
    },
    updateOptionGroupField: (
      state,
      action: PayloadAction<{
        item: OptionGroup;
        value: Field;
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any, index) => {
        if (item.id === action.payload.item.id) {
          item[action.payload.value.field] = action.payload.value.value;
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    updateOptionGroupRule: (
      state,
      action: PayloadAction<{
        optionGroupId: string;
        optionRule: OptionRule;
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any, index) => {
        if (item.id === action.payload.optionGroupId) {
          item.optionRule = action.payload.optionRule;
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    changePositionOptionGroup: (state, action: PayloadAction<{ optionGroups: OptionGroup[] }>) => {
      state.optionGroups = action.payload.optionGroups;
    },
    updateOptionGroupMenuItems: (
      state,
      action: PayloadAction<{
        item: OptionGroup;
        menuItemIds: string[];
      }>
    ) => {
      let optionGroups = state.optionGroups;
      optionGroups = optionGroups.map((item: any, index) => {
        if (item.id === action.payload.item.id) {
          return {
            ...item,
            menuItemIds: action.payload.menuItemIds
          };
        }
        return item;
      });
      state.optionGroups = optionGroups;
    },
    addOption: (
      state,
      action: PayloadAction<{ optionGroupId: string; option: Option }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = [action?.payload?.option, ...optionGroup.options];
      }
      state.optionGroups = optionGroups;
    },
    updateOption: (
      state,
      action: PayloadAction<{
        optionGroupId: string;
        optionId: string;
        values: Field;
      }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        let options = optionGroup.options;
        options = options.map((item: any) => {
          if (item.id === action.payload.optionId) {
            item[action.payload.values.field] = action.payload.values.value;
            return item;
          }
          return item;
        });
        optionGroup.options = [...options];
      }
      
      state.optionGroups = optionGroups;
    },
    deleteOptionState: (
      state,
      action: PayloadAction<{ optionGroupId: string; optionId: string }>
    ) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = optionGroup.options.filter(
          (item, index) => item?.id != action?.payload?.optionId
        );
      }
      state.optionGroups = optionGroups;
    },
    changePositionOption: (state, action: PayloadAction<{ optionGroupId: string, options: any }>) => {
      let optionGroups = state.optionGroups;
      const optionGroup = optionGroups.find(
        (x) => x?.id == action?.payload?.optionGroupId
      );
      if (optionGroup != null) {
        optionGroup.options = action?.payload?.options;
      }
      state.optionGroups = optionGroups;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  addItemToCategory,
  changePositionCategory,
  changePositionItem,
  changePositionMenuItems,
  setPlaces,
  setCategories,
  updateCategoryField,
  updateItemField,
  searchItems,
  deleteItemState,
  deleteCategoryState,
  setOptionGroups,
  deleteOptionGroupState,
  updateOptionGroupField,
  updateOptionGroupMenuItems,
  updateOptionGroupRule,
  deleteOptionState,
  addOption,
  updateOption,
  changePositionOption,
  changePositionOptionGroup,
  setCategoriesOriginal
} = PlaceSlice.actions;

export default PlaceSlice.reducer;
