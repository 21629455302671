import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "./slices/user/userSlice";
import placeReducer from "./slices/places/placeSlice";
import orderReducer from "./slices/order/orderSlice";
import customersReducer from "./slices/customers/customersSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    places: placeReducer,
    order: orderReducer,
    customers: customersReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
