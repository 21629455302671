import React from "react";

export const GeneralHide = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.90725 1.32257C2.60216 1.01748 2.1075 1.01748 1.8024 1.32257C1.4973 1.62767 1.4973 2.12233 1.8024 2.42743L4.52553 5.15055C3.99445 5.55714 3.52055 5.99107 3.10544 6.41832C2.32233 7.22433 1.72705 8.02759 1.32736 8.62883C1.12704 8.93017 0.974582 9.18268 0.871088 9.36201C0.819311 9.45172 0.779701 9.52328 0.7524 9.57365C0.738747 9.59883 0.728163 9.61874 0.720668 9.63298L0.71174 9.65006L0.709015 9.65533L0.708088 9.65714L0.707733 9.65783C0.707586 9.65812 0.707451 9.65838 1.40622 10L0.707733 9.65783C0.592931 9.88233 0.597968 10.1482 0.721679 10.3681L0.723276 10.371L0.726144 10.376L0.735679 10.3926C0.743718 10.4066 0.755118 10.4262 0.76983 10.4511C0.79925 10.5008 0.841952 10.5717 0.89756 10.6607C1.00872 10.8387 1.17179 11.0897 1.38381 11.3894C1.80686 11.9875 2.42986 12.7867 3.22966 13.5888C4.80982 15.1735 7.18797 16.875 10.1562 16.875C11.9528 16.875 13.5041 16.2521 14.783 15.408L17.5725 18.1976C17.8776 18.5027 18.3723 18.5027 18.6774 18.1976C18.9825 17.8925 18.9825 17.3978 18.6774 17.0927L2.90725 1.32257ZM13.6695 14.2945L12.3495 12.9745C11.7327 13.41 10.9751 13.6667 10.1562 13.6667C8.08515 13.6667 6.40622 12.025 6.40622 10C6.40622 9.16973 6.68846 8.40391 7.16423 7.78926L5.62522 6.25025C5.11441 6.62793 4.65149 7.04525 4.23825 7.47057C3.53699 8.19234 2.99946 8.91686 2.63743 9.46145C2.5018 9.66547 2.39152 9.84317 2.30715 9.98461C2.39953 10.1286 2.52058 10.3107 2.66887 10.5203C3.05636 11.0681 3.62478 11.7966 4.34841 12.5223C5.81512 13.9932 7.81198 15.3472 10.1562 15.3472C11.4661 15.3472 12.6437 14.9241 13.6695 14.2945ZM8.27796 8.90299C8.08159 9.22374 7.96872 9.59898 7.96872 10C7.96872 11.1813 8.9481 12.1389 10.1562 12.1389C10.5488 12.1389 10.9172 12.0378 11.2357 11.8607L8.27796 8.90299ZM0.721679 10.3681L1.40206 10.0022C1.06284 10.1847 0.892709 10.2761 0.807401 10.3219C0.723182 10.3672 0.721625 10.368 0.721679 10.3681Z" fill="currentColor" />
            <path d="M9.80856 6.34888L13.8817 10.422C13.8979 10.2835 13.9062 10.1427 13.9062 10C13.9062 7.97496 12.2273 6.33333 10.1562 6.33333C10.039 6.33333 9.92303 6.33859 9.80856 6.34888Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3649 10.5423C17.0239 11.0619 16.5281 11.7444 15.8917 12.432L16.9859 13.5262C17.7254 12.7374 18.2941 11.9553 18.68 11.3674C18.8773 11.0667 19.028 10.8149 19.1305 10.6362C19.1818 10.5468 19.221 10.4755 19.2481 10.4255C19.2616 10.4004 19.2721 10.3807 19.2795 10.3666L19.2883 10.3497L19.291 10.3446L19.2919 10.3428L19.2922 10.3421C19.3972 10.1369 19.4025 9.89581 19.3062 9.68653L18.5937 10C19.3062 9.68653 19.306 9.68625 19.3059 9.68595L19.3056 9.68523L19.3047 9.68337L19.3022 9.67798L19.294 9.66066C19.2872 9.64625 19.2775 9.62617 19.2651 9.60081C19.2402 9.5501 19.204 9.47817 19.1566 9.38805C19.0619 9.20793 18.9218 8.95449 18.7363 8.65212C18.3662 8.04894 17.81 7.24271 17.065 6.43343C15.5844 4.82483 13.2806 3.125 10.1562 3.125C9.05697 3.125 8.04962 3.33551 7.13788 3.67821L8.35326 4.89359C8.92082 4.73988 9.52219 4.65278 10.1562 4.65278C12.6568 4.65278 14.5718 6.0085 15.9037 7.45546C16.565 8.17395 17.0635 8.89551 17.3965 9.43816C17.5287 9.65364 17.634 9.83959 17.7124 9.98421C17.6261 10.1312 17.5099 10.3214 17.3649 10.5423ZM19.2922 10.3421C19.2924 10.3419 19.2925 10.3416 18.5937 10L19.2922 10.3421Z" fill="currentColor" />
        </svg>

    )
}