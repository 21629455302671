import React from "react";

export const WarningTriangleIcon = () => {
    return (
        <svg width="24" height="24" strokeWidth="2.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.0429 21H3.95705C2.41902 21 1.45658 19.3364 2.22324 18.0031L10.2662 4.01533C11.0352 2.67792 12.9648 2.67791 13.7338 4.01532L21.7768 18.0031C22.5434 19.3364 21.581 21 20.0429 21Z"
                stroke="#E41D41" strokeLinecap="round" />
            <path d="M12 9V13" stroke="#E41D41" strokeLinecap="round" />
            <path d="M12 17.01L12.01 16.9989" stroke="#E41D41" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
export const WarningErrorIcon = ({width = 24, height = 24}: {width?: number, height?: number}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width={width} height={height}>
        <rect width="256" height="256" fill="none"/>
        <circle cx="128" cy="128" r="100" fill="none" stroke="#30A9A6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="15"/>
        <line x1="128" y1="70" x2="128" y2="130" fill="none" stroke="#30A9A6" strokeLinecap="round" strokeLinejoin="round" strokeWidth="15"/>
        <circle cx="129" cy="170" r="5" stroke="#30A9A6" strokeWidth="15"/>
      </svg>
    )
}