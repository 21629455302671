import { AlertIcon } from "assets/icons/Alert";
import * as React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import { useState } from "react";
import { FormatCurrency } from "utils";
import { Tooltip } from "antd";
import { Currencies } from "constant/currencies";
import ChangeCurrencyTooltip from "components/tooltip/ChangeCurrencyTooltip";

interface CurrencyInputProps extends UseControllerProps {
  symbolClickLink?: string;
}

function InputCurrency(props: CurrencyInputProps | any) {
  const { field, fieldState, formState } = useController(props);
  const [isEditable, setInputFieldEditable] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleNotEditing = () => {
    setInputFieldEditable(true);
  };
  const toggleEditing = () => {
    setInputFieldEditable(false);
  };
  const getSymbol = (currency: any) => {
    return Currencies.find((x) => x.code == currency)?.symbol;
  };
  const changeCurrencyTooltip = (
    <ChangeCurrencyTooltip
      link={props.symbolClickLink}
      onChangeShowToolTip={(x) => setTooltipVisible(x)}
    />
  );

  return (
    <div className="mt-2">
      <div className="relative">
        {isEditable && props?.currency && (
          <div
            className={`flex rounded-md border
              ${
                fieldState.invalid
                  ? "border-default-error"
                  : "border-neutral-30"
              }
              ${props?.disabled ? "" : "bg-white"} ${props.className}`}
          >
            <Tooltip
              title={changeCurrencyTooltip}
              trigger="click"
              color="#fff"
              overlayStyle={{ maxWidth: "500px" }}
              open={tooltipVisible}
            >
              <button
                type="button"
                onClick={() => setTooltipVisible(true)}
                className="flex px-2 py-2 outline-none border-r w-[44px] justify-center border-neutral-30"
              >
                {getSymbol(props?.currency || "USD")}
              </button>
            </Tooltip>
            <input
              {...field}
              placeholder={props.placeholder}
              onChange={field.onChange}
              onBlur={toggleEditing}
              type="number"
              autoFocus
              value={field.value}
              disabled={props?.disabled}
              className={`px-2 py-2 block outline-none w-full font-normal border-0 rounded-md text-base`}
            />
          </div>
        )}
        {!isEditable && props?.currency && (
          <div className="flex rounded-md border border-neutral-30">
            <Tooltip
              title={changeCurrencyTooltip}
              trigger="click"
              color="#fff"
              overlayStyle={{ maxWidth: "500px" }}
              open={tooltipVisible}
            >
              <button
                type="button"
                onClick={() => setTooltipVisible(true)}
                className="flex px-2 py-2 outline-none border-r w-[40px] justify-center border-neutral-30"
              >
                {getSymbol(props?.currency || "USD")}
              </button>
            </Tooltip>
            <input
              {...field}
              placeholder={props.placeholder}
              onChange={field.onChange}
              type="text"
              onFocus={toggleNotEditing}
              value={
                FormatCurrency(field.value, props?.currency || "USD", true) ||
                ""
              }
              disabled={props?.disabled}
              readOnly
              className={`px-2 py-2 flex-1 block w-full rounded-md font-normal text-base
                ${
                  fieldState.invalid
                    ? "border-default-error"
                    : "border-neutral-30"
                }
                ${props?.disabled ? "" : "bg-white"} ${props.className}`}
            />
          </div>
        )}
      </div>
      {fieldState?.error?.message && (
        <span className="text-sm text-default-error mt-2.5 flex items-center space-x-2">
          <span className="flex-none">
            <AlertIcon />
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: fieldState?.error?.message }}
          />
        </span>
      )}
    </div>
  );
}

export { InputCurrency };
