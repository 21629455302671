import React from "react";
import { DeleteIcon } from "assets/icons";
import { Link } from "react-router-dom";

interface ChangeCurrencyTooltipProps {
  link: string;
  onChangeShowToolTip: (x: boolean) => void;
}

const ChangeCurrencyTooltip = ({
  link,
  onChangeShowToolTip,
}: ChangeCurrencyTooltipProps) => {
  return (
    <div>
      <div className="header flex justify-between p-4 gap-4">
        <p>
          Click{" "}
          <Link className="underline" to={link}>
            here
          </Link>{" "}
          to change currency
        </p>
        <div onClick={() => onChangeShowToolTip(false)} className="delete-icon">
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default ChangeCurrencyTooltip;
