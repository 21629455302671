import React from "react";

export const CopyAddress = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 3.4375H3.75C3.23223 3.4375 2.8125 3.85723 2.8125 4.375V11.875C2.8125 12.3928 3.23223 12.8125 3.75 12.8125H5V14.375H3.75C2.36929 14.375 1.25 13.2557 1.25 11.875V4.375C1.25 2.99429 2.36929 1.875 3.75 1.875H11.25C12.6307 1.875 13.75 2.99429 13.75 4.375V5H12.1875V4.375C12.1875 3.85723 11.7678 3.4375 11.25 3.4375Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.25 8.75C6.25 7.36929 7.36929 6.25 8.75 6.25H16.25C17.6307 6.25 18.75 7.36929 18.75 8.75V16.25C18.75 17.6307 17.6307 18.75 16.25 18.75H8.75C7.36929 18.75 6.25 17.6307 6.25 16.25V8.75ZM8.75 7.8125H16.25C16.7678 7.8125 17.1875 8.23223 17.1875 8.75V16.25C17.1875 16.7678 16.7678 17.1875 16.25 17.1875H8.75C8.23223 17.1875 7.8125 16.7678 7.8125 16.25V8.75C7.8125 8.23223 8.23223 7.8125 8.75 7.8125Z" fill="black" />
        </svg>
    )
}

