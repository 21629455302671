
import { PAYMENT_ACCOUNT, HOST_URL } from "../constant/api.constant";
import axiosInstance from "./restful.service";

const createConnectedLink = (payload: any) => {
    return axiosInstance.post(HOST_URL + PAYMENT_ACCOUNT.CREATE_CONNECTED_LINK, payload);
}

const getStatus = () => {
    return axiosInstance.get(HOST_URL + PAYMENT_ACCOUNT.GET_STATUS);
}

const PaymentAccountService = {
    getStatus,
    createConnectedLink
};

export default PaymentAccountService;