
import React from "react"
export const UnionIcon = () => {
    return (
        <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4.625C0.964467 4.625 0.125001 3.78553 0.125001 2.75C0.125001 1.71447 0.964467 0.875 2 0.875C3.03553 0.875 3.875 1.71447 3.875 2.75C3.875 3.78553 3.03553 4.625 2 4.625Z" fill="currentColor" />
            <path d="M2 10.875C0.964466 10.875 0.125 10.0355 0.125 9C0.125 7.96447 0.964467 7.125 2 7.125C3.03553 7.125 3.875 7.96447 3.875 9C3.875 10.0355 3.03553 10.875 2 10.875Z" fill="currentColor" />
            <path d="M0.125 15.25C0.125 16.2855 0.964466 17.125 2 17.125C3.03553 17.125 3.875 16.2855 3.875 15.25C3.875 14.2145 3.03553 13.375 2 13.375C0.964466 13.375 0.125 14.2145 0.125 15.25Z" fill="currentColor" />
        </svg>
    )
}


