import React from "react";

export const ExclamationCircle = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00008 7.33332C9.46032 7.33332 9.83342 7.70642 9.83342 8.16666V13.1667C9.83342 13.6269 9.46032 14 9.00008 14C8.53984 14 8.16675 13.6269 8.16675 13.1667V8.16666C8.16675 7.70642 8.53984 7.33332 9.00008 7.33332Z"
        fill="#737992"
      />
      <path
        d="M9.00008 5.66666C9.46032 5.66666 9.83342 5.29356 9.83342 4.83332C9.83342 4.37309 9.46032 3.99999 9.00008 3.99999C8.53984 3.99999 8.16675 4.37309 8.16675 4.83332C8.16675 5.29356 8.53984 5.66666 9.00008 5.66666Z"
        fill="#737992"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00008 17.3333C13.6025 17.3333 17.3334 13.6024 17.3334 8.99999C17.3334 4.39762 13.6025 0.666656 9.00008 0.666656C4.39771 0.666656 0.666748 4.39762 0.666748 8.99999C0.666748 13.6024 4.39771 17.3333 9.00008 17.3333ZM9.00008 15.6667C12.682 15.6667 15.6667 12.6819 15.6667 8.99999C15.6667 5.31809 12.682 2.33332 9.00008 2.33332C5.31818 2.33332 2.33341 5.31809 2.33341 8.99999C2.33341 12.6819 5.31818 15.6667 9.00008 15.6667Z"
        fill="#737992"
      />
    </svg>
  );
};
