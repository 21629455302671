export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const AUTH = {
  VERIFY_EMAIL: "/auth/sign-in",
  SIGN_IN: "/auth/sign-in",
  SIGN_UP: "/auth/sign-up",
  RESIGN_UP: "/admin/resignup",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  LOGOUT: "/auth/logout",
  PASSWORD: "/users/me/password",
  REFRESH_TOKEN: "/auth/refresh-token",
  USER_PROFILE: (userId: string) => `/users/${userId}/profiles`,
  USER_ME: "/users/me",
  UPLOAD_AVATAR: "/resources/upload-url",
  UPLOAD_FILE: (userId: string) => `/users/${userId}/files`,
  UPLOAD_AVATAR_FORM_DATA: "",
  UPLOAD_AVATAR_FINAL: "/users/me/avatar",
  PASSCODE: "/auth/passcode",
  SEARCH_USERS: (emailPrefix: string) => `/admin/search-users/${emailPrefix}`,
  SEARCH_PARTNER_USERS: (partnerEmail: string, emailPrefix: string) => `/partner/${partnerEmail}/search-partner-users/${emailPrefix}`,
  REQUESTS: (userId: string) =>  `/users/${userId}/requests`,
  PLACES: (userId: string) => `/users/${userId}/places`,
  TEMPLATES: () => `/templates`,
  CUSTOMERS: (
    userId: string,
    placeId: string,
    filter?: string,
    page?: number
  ) => {
    let url = `/users/${userId}/places/${placeId}/customers?`;
    if (filter) {
      url += `filter=${filter}&`;
    }
    if (page) {
      url += `page=${page}&`;
    }
    return url;
  },
  CUSTOMER: (userId: string, placeId: string, customerId: string) =>
    `/users/${userId}/places/${placeId}/customers/${customerId}`,
  PLACES_FIRST: (userId: string) => `/users/${userId}/first_place`,
  PLACES_BY_ID: (userId: string, placeId?: string) =>
    `/users/${userId}/places/${placeId}`,
  ORDER_BY_ID: (userId: string, placeId?: string, orderId?: string) =>
    `/users/${userId}/places/${placeId}/orders/${orderId}`,
  CATEGORY: (userId: string, placeId?: string) =>
    `/users/${userId}/places/${placeId}/categories`,
  CATEGORIES: (userId: string, placeId?: string, count?: number) =>
    `/users/${userId}/places/${placeId}/categories/${count}`,
  MENU_ITEM: (userId: string) => `/users/${userId}/menu-items`,
  MENU_ITEMS: (userId: string, count: number) =>
    `/users/${userId}/menu-items/${count}`,
  MENU_ITEM_BY_ID: (userId: string, menuId?: string) =>
    `/users/${userId}/menu-items/${menuId}`,
  PLACES_PUBLIC: (name?: string) => `/places/${name}`,
  LANGUAGE: (userId: string, placeId?: string) =>
    `/users/${userId}/places/${placeId}/languages`,
  SUBSCRIPTION: (userId: string) => `/users/${userId}/subscriptions`,
  INVOICE: (userId: string) => `/users/${userId}/invoices`,
  PAYMENT_LINK: (userId: string, periodUnit: number) => `/users/${userId}/subscriptions/checkout?subscriptionPeriodUnit=${periodUnit}`,
  SEARCH_ORDER: (
    userId: string,
    placeId?: string,
    filter?: string,
    page?: number
  ) => {
    let endpoint = `/users/${userId}/places/${placeId}/orders`;
    if (filter && page) {
      endpoint += `?filter=${filter}&page=${page}`;
    } else if (filter) {
      endpoint += `?filter=${filter}`;
    } else if (page) {
      endpoint += `?page=${page}`;
    }
    return endpoint;
  },
  UPDATE_ORDER_STATUS: (
    userId: string,
    placeId?: string,
    orderId?: number,
    status?: number
  ) => {
    let endpoint = `/users/${userId}/places/${placeId}/orders/${orderId}/order-status`;
    if (status) {
      endpoint += `?status=${status}`;
    }
    return endpoint;
  },
  UPDATE_PAYMENT_STATUS: (
    userId: string,
    placeId?: string,
    orderId?: number,
    status?: number
  ) => {
    let endpoint = `/users/${userId}/places/${placeId}/orders/${orderId}/payment-status`;
    if (status) {
      endpoint += `?status=${status}`;
    }
    return endpoint;
  },
  CANCEL_USER_SUBSCRIPTION: (userId: string) =>
    `/users/${userId}/subscriptions/cancel`,
  RESUME_CANCEL_SUBSCRIPTION: (userId: string) =>
    `/users/${userId}/subscriptions/resume`,
  UPDATED_SUBSCRIPTION: (userId: string, planId: string) =>
    `/users/${userId}/subscriptions/update?planId=${planId}`,
  PAYMENT: (placeId?: string) => `/places/${placeId}/payment-methods`,
  GET_PAYMENT: (placeId?: string) => `/places/${placeId}/payment-methods`,
  UPDATE_PAYMENT: (placeId?: string, paymentMethodId?: string) =>
    `/places/${placeId}/payment-methods/${paymentMethodId}`,
  DEL_PAYMENT: (placeId?: string, paymentMethodId?: string) =>
    `/places/${placeId}/payment-methods/${paymentMethodId}`,
  GET_INVOICE: (userId?: string, placeId?: string, orderId?: string) =>
    `/users/${userId}/places/${placeId}/orders/${orderId}/invoice-link`,
  OPTION_GROUP: (userId: string, placeId?: string) => `/users/${userId}/places/${placeId}/option-groups`,
  OPTION: (userId: string, placeId?: string, optionGroupId?: string) => `/users/${userId}/places/${placeId}/option-groups/${optionGroupId}/options`,
  CREATE_MINIWEBSITE: (userId: string) => `/users/${userId}/miniwebsite`,
  GET_MINIWEBSITE: (userId: string) => `/users/${userId}/miniwebsite`,
  CREATE_MINIWEBSITE_LINK: (userId: string, miniwebsiteId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/links`,
  UPDATE_MINIWEBSITE_LINK: (userId: string, miniwebsiteId: string, linkId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/links/${linkId}`,
  DELETE_MINIWEBSITE_LINK: (userId: string, miniwebsiteId: string, linkId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/links/${linkId}`,
  UPDATE_MINIWEBSITE_LINKS_POSITION: (userId: string, miniwebsiteId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/links/position`,
  UPDATE_MINIWEBSITE_BUSINESS_INFORMATION: (userId: string, miniwebsiteId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/businessInformation`,
  UPDATE_MINIWEBSITE_BRAND_INFORMATION: (userId: string, miniwebsiteId: string) => `/users/${userId}/miniwebsite/${miniwebsiteId}/brandInformation`,
  UPDATE_PAYMENT_METHOD_LINK: (userId: string, redirectUrl: string) => `/users/${userId}/subscriptions/update-payment-methods?redirectUrl=${redirectUrl}`
};

export const PAYMENT_ACCOUNT = {
  CREATE_CONNECTED_LINK: "/stripe/register",
  GET_STATUS: "/stripe/status",
}

export const ANALYTICS = {
  GET_REPORT: (userId: string, placeId: string, startTime: string, endTime: string) =>
    `/users/${userId}/places/${placeId}/reports?startTime=${startTime}&endTime=${endTime}`,
}
