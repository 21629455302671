import { AUTH, HOST_URL } from "../constant/api.constant";
import axiosInstance, { RestfulService } from "./restful.service";


const getSubscription = (userId: string) => {
    return axiosInstance.get(HOST_URL + AUTH.SUBSCRIPTION(userId));
};
const getPaymentLinkById = (userId: string, periodUnit: number) => {
    return axiosInstance.get(HOST_URL + AUTH.PAYMENT_LINK(userId, periodUnit));
};
const cancelUserSubscription = (userId: string) =>{
    return axiosInstance.put(HOST_URL + AUTH.CANCEL_USER_SUBSCRIPTION(userId) )
};
const resumeCancelledSubscription = (userId :string) => {
    return axiosInstance.put(HOST_URL + AUTH.RESUME_CANCEL_SUBSCRIPTION(userId))
};
const getUpdatedSubscription = (userId :string, planId: string) => {
    return axiosInstance.put(HOST_URL + AUTH.UPDATED_SUBSCRIPTION(userId, planId))
};
const getUpdatePaymentMethodLinkById = (userId :string, redirectUrl: string) => {
    return axiosInstance.put(HOST_URL + AUTH.UPDATE_PAYMENT_METHOD_LINK(userId, redirectUrl))
};

const UserSubscriptionService = {
    getSubscription,
    getPaymentLinkById,
    cancelUserSubscription,
    resumeCancelledSubscription,
    getUpdatedSubscription,
    getUpdatePaymentMethodLinkById,
};

export default UserSubscriptionService;