import { AlertIcon } from "assets/icons/Alert";
import * as React from "react";
import { useForm, useController, UseControllerProps } from "react-hook-form";
import { useState } from "react";
import { FormatCurrency } from "utils";

interface InputProps extends UseControllerProps {
  hintText?: string;
  showLength?: boolean;
}

function Input(props: InputProps | any) {
  const { field, fieldState, formState } = useController(props);
  const [isEditable, setInputFieldEditable] = useState(false);
  const toggleNotEditing = () => {
    setInputFieldEditable(true);
  };
  const toggleEditing = () => {
    setInputFieldEditable(false);
  };
  return (
    <div className="mt-2">
      <div className="relative">
        {isEditable && props?.currency && (
          <input
            {...field}
            placeholder={props.placeholder}
            onChange={field.onChange}
            onBlur={toggleEditing}
            type="number"
            autoFocus
            value={field.value}
            disabled={props?.disabled}
            className={`px-2 py-2  block w-full font-normal rounded-md border border-neutral-30 text-base
            ${fieldState.invalid ? "border-default-error" : "border-neutral-30"}
            ${props?.disabled ? "" : "bg-white"} ${props.className}`}
          />
        )}
        {!isEditable && props?.currency && (
          <input
            {...field}
            placeholder={props.placeholder}
            onChange={field.onChange}
            type="text"
            onFocus={toggleNotEditing}
            value={FormatCurrency(field.value, props?.currency || "USD") || ""}
            disabled={props?.disabled}
            readOnly
            className={`px-2 py-2  block w-full font-normal rounded-md border border-neutral-30 text-base
                ${
                  fieldState.invalid
                    ? "border-default-error"
                    : "border-neutral-30"
                }
                ${props?.disabled ? "" : "bg-white"} ${props.className}`}
          />
        )}
        {!isEditable && !props?.currency && (
          <input
            {...field}
            placeholder={props.placeholder}
            onChange={field.onChange}
            type={props.type}
            value={field.value}
            disabled={props?.disabled}
            maxLength={props?.maxLength}
            className={`px-2 py-2  block w-full font-normal rounded-md border border-neutral-30 text-base
                      ${
                        fieldState.invalid
                          ? "border-default-error"
                          : "border-neutral-30"
                      }
                      ${props?.disabled ? "" : "bg-white"} ${props.className}`}
          />
        )}

        {props?.suffix && (
          <button
            type="button"
            onClick={props.onSuffix}
            className="absolute right-2.5 top-2.5"
          >
            {props?.suffix}
          </button>
        )}
      </div>
      {props?.hintText && (
        <span className="text-sm text-neutral-50 mt-2.5 flex items-center space-x-2">
          <div className="flex w-full flex-row text-neutral-50">
            <span
              className="flex-1"
              dangerouslySetInnerHTML={{ __html: props?.hintText }}
            ></span>
            {props?.maxLength && props?.showLength}
            <span>
              {field.value.length}/{props.maxLength}
            </span>
          </div>
        </span>
      )}
      {fieldState?.error?.message && (
        <span className="text-sm text-default-error mt-2.5 flex items-center space-x-2">
          <span className="flex-none">
            <AlertIcon />
          </span>
          <span
            dangerouslySetInnerHTML={{ __html: fieldState?.error?.message }}
          />
        </span>
      )}
    </div>
  );
}

export { Input };
