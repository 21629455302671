import React from "react";

export const LoadingIndicator = ({}: {}) => {
  return (
    <div
      style={{
        height: "100px",
        width: "100px",
        top: "50%",
        left: "50%",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "50%",
          border: "3px solid",
          borderColor: "#30A9A6 transparent",
          animation: "loader-rotate 1s infinite linear",
        }}
      ></div>
    </div>
  );
};
