import React from "react";

export const DragIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      version="1.1"
      fill="currentColor"
    >
      <g id="surface1">
        <path
          d="M 5.519531 1.582031 C 4.9375 1.796875 4.558594 2.285156 4.511719 2.886719 C 4.4375 3.78125 5.21875 4.5625 6.113281 4.488281 C 6.734375 4.4375 7.246094 4.023438 7.433594 3.429688 C 7.53125 3.117188 7.5 2.6875 7.359375 2.382812 C 7.203125 2.054688 6.976562 1.820312 6.652344 1.664062 C 6.414062 1.542969 6.359375 1.53125 6.050781 1.523438 C 5.78125 1.511719 5.675781 1.527344 5.519531 1.582031 Z M 5.519531 1.582031 "
        />
        <path
          d="M 11.546875 1.570312 C 11.125 1.726562 10.828125 1.984375 10.640625 2.382812 C 10.535156 2.605469 10.53125 2.644531 10.53125 3.007812 C 10.53125 3.375 10.535156 3.40625 10.648438 3.640625 C 11.117188 4.585938 12.355469 4.789062 13.074219 4.023438 C 13.78125 3.28125 13.566406 2.105469 12.640625 1.652344 C 12.425781 1.542969 12.359375 1.53125 12.0625 1.523438 C 11.804688 1.511719 11.683594 1.527344 11.546875 1.570312 Z M 11.546875 1.570312 "
        />
        <path
          d="M 5.695312 7.539062 C 5.398438 7.601562 5.171875 7.726562 4.945312 7.949219 C 4.355469 8.535156 4.355469 9.460938 4.945312 10.050781 C 5.53125 10.632812 6.421875 10.644531 7.015625 10.085938 C 7.335938 9.78125 7.488281 9.4375 7.488281 9 C 7.488281 8.566406 7.335938 8.21875 7.019531 7.917969 C 6.792969 7.703125 6.601562 7.605469 6.296875 7.539062 C 6.023438 7.480469 5.953125 7.480469 5.695312 7.539062 Z M 5.695312 7.539062 "
        />
        <path
          d="M 11.6875 7.539062 C 11.398438 7.605469 11.203125 7.707031 10.980469 7.917969 C 10.664062 8.21875 10.511719 8.566406 10.511719 9 C 10.511719 9.4375 10.664062 9.78125 10.984375 10.085938 C 11.578125 10.644531 12.46875 10.632812 13.054688 10.050781 C 13.644531 9.460938 13.644531 8.539062 13.054688 7.949219 C 12.824219 7.722656 12.566406 7.582031 12.253906 7.527344 C 12.003906 7.480469 11.960938 7.480469 11.6875 7.539062 Z M 11.6875 7.539062 "
        />
        <path
          d="M 5.621094 13.550781 C 5.226562 13.660156 4.84375 13.976562 4.664062 14.328125 C 4.25 15.160156 4.675781 16.152344 5.570312 16.433594 C 5.71875 16.480469 5.851562 16.492188 6.082031 16.480469 C 6.351562 16.46875 6.425781 16.449219 6.65625 16.335938 C 7.15625 16.085938 7.457031 15.636719 7.480469 15.078125 C 7.5 14.707031 7.4375 14.476562 7.246094 14.179688 C 7.085938 13.933594 6.804688 13.703125 6.53125 13.597656 C 6.296875 13.511719 5.84375 13.484375 5.621094 13.550781 Z M 5.621094 13.550781 "
        />
        <path
          d="M 11.601562 13.554688 C 11.195312 13.664062 10.847656 13.960938 10.648438 14.359375 C 10.535156 14.59375 10.53125 14.625 10.53125 14.992188 C 10.53125 15.355469 10.535156 15.394531 10.640625 15.617188 C 10.796875 15.945312 11.023438 16.179688 11.351562 16.339844 C 11.609375 16.46875 11.625 16.46875 12.007812 16.46875 C 12.378906 16.46875 12.402344 16.464844 12.640625 16.351562 C 13.566406 15.894531 13.78125 14.722656 13.074219 13.976562 C 12.691406 13.570312 12.136719 13.414062 11.601562 13.554688 Z M 11.601562 13.554688 "
        />
      </g>
    </svg>
  );
};
