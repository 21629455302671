import React from 'react';
import Routes from 'routes/Routes';
import AuthProvider from 'contexts/Auth/AuthProvider';
import { Helmet } from 'react-helmet';
import "trix";

const App: React.FC = () => (
  <div data-theme={process.env.REACT_APP_WHITELABEL} className={process.env.REACT_APP_LANGUAGE_DEFAULT}>
    <Helmet>
      <meta name="description" content="Create free digital QR Code menu for restaurants. Customize and edit your menu in real time." />
    </Helmet>
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </div>
);

export default App;