import React, { useEffect } from "react";
import logo from "assets/svg/logo.svg";
import { Link } from "react-router-dom";
import NavItem from "./NavItem";
import { HelpNavIcon } from "assets/icons/HelpNav";
import Profile from "./Profile";
import { DeleteIcon, HamburgerIcon } from "assets/icons";
import { Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useAuth } from "contexts/Auth/AuthContext";
import { UserType } from "types";
import UserService from "services/user.service";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import Connector from "signalr-connection";

const NavTop: React.FC<{ wrapperStyle?: React.CSSProperties }> = ({
  wrapperStyle,
}) => {
  let [isShowing, setIsShowing] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingPartnerUsers, setLoadingPartnerUsers] = useState(false);

  const auth = useAuth();

  const closeDialogs = () => {
    setIsShowing(false);
  };

  const loadPartnerOptions = async (
    searchEmail: string,
    callback: (options: any[]) => void
  ) => {
    var currentLoginUser = auth.getLoginUser();
    if (searchEmail?.length < 3) {
      const defaultUserList = [
        {
          value: currentLoginUser.userId,
          label: currentLoginUser.email,
        },
      ];
      callback(defaultUserList);
      return defaultUserList;
    }

    setLoadingPartnerUsers(true);
    const response = await UserService.searchPartnerUsersByEmail(
      currentLoginUser.email,
      searchEmail
    );
    let userOptions = response?.data.map((user: UserType) => {
      return {
        label: user.email,
        value: user?.id,
      };
    });

    setLoadingPartnerUsers(false);
    callback(userOptions);
    return userOptions;
  };

  const loadAdminOptions = async (
    searchEmail: string,
    callback: (options: any[]) => void
  ) => {
    if (searchEmail?.length < 3) {
      const defaultUserList = [
        {
          value: auth.getCurrentUser().userId,
          label: auth.getCurrentUser().email,
        },
      ];
      callback(defaultUserList);
      return defaultUserList;
    }

    setLoadingUsers(true);
    const response = await UserService.searchUsersByEmail(searchEmail);
    let userOptions = response?.data.map((user: UserType) => {
      return {
        label: user.email,
        value: user?.id,
      };
    });

    setLoadingUsers(false);
    callback(userOptions);
    return userOptions;
  };

  const [orderBadge, setOrderBadge] = useState(0);

  const { subscribe } = Connector();
  subscribe("NavTop", "OrderUpdated", (_, data) => {
    setOrderBadge(data.numberOfNewOrders);
  });

  const debounceLoadUsers = _.debounce(loadAdminOptions, 1000);
  const debounceLoadPartnerUsers = _.debounce(loadPartnerOptions, 1000);

  const onUserSelect = (event: any) => {
    if (event === null) {
      const loginUser = auth.getLoginUser();
      auth.onCurrentUserChanged({
        userId: loginUser.userId,
        email: loginUser.email,
      });
      return;
    }

    auth.onCurrentUserChanged({ userId: event.value, email: event.label });
  };

  const help = () => {
    window.open("https://menubly.usetiful.help/", "_blank");
  };

  return (
    <>
      <div
        className="NavTop flex justify-between sticky top-0 z-[999] items-center w-full px-4 py-2.5 bg-white"
        style={wrapperStyle}
      >
        <Link to="/">
          <img src={logo} alt="Menubly" />
        </Link>
        <ul className="hidden lg:flex lg:ml-[58px] space-x-8">
          <li>
            <NavItem name="Menus" url="/" childrenUrl="/places/:id" />
          </li>
          <li>
            <NavItem name="Mini-website" url="/miniwebsite" />
          </li>
          <li>
            <NavItem name="Analytics" url="/analytics" />
          </li>
          <li>
            <NavItem
              name="Orders"
              badge={orderBadge}
              url="/orders"
              childrenUrl="/orders/:id"
            />
          </li>
          <li>
            <NavItem
              name="Customers"
              url="/customers"
              childrenUrl="/customers/:id"
            />
          </li>
          <li>
            <NavItem name="Items Collection" url="/items-collection" />
          </li>
        </ul>

        <div className="lg:flex ml-auto">
          <div className="mr-6 ml-2 lg:w-[280px]">
            {auth.user?.isAdmin && (
              <div className="item-center w-full">
                <AsyncSelect
                  isLoading={loadingUsers}
                  defaultValue={{
                    value: auth.getCurrentUser().userId,
                    label: auth.getCurrentUser().email,
                  }}
                  loadOptions={debounceLoadUsers}
                  onChange={onUserSelect}
                  noOptionsMessage={() =>
                    "Please type at least 3 chars to search for user email"
                  }
                  hideSelectedOptions={false}
                  isClearable={true}
                  placeholder={auth.getCurrentUser().email}
                />
              </div>
            )}
            {auth.user?.isPartner && (
              <div className="item-center w-full">
                <AsyncSelect
                  isLoading={loadingPartnerUsers}
                  defaultValue={{
                    value: auth.getCurrentUser().userId,
                    label: auth.getCurrentUser().email,
                  }}
                  loadOptions={debounceLoadPartnerUsers}
                  onChange={onUserSelect}
                  noOptionsMessage={() =>
                    "Please type at least 3 chars to search for user email"
                  }
                  hideSelectedOptions={false}
                  isClearable={true}
                  placeholder={auth.getCurrentUser().email}
                />
              </div>
            )}
          </div>
          <div className="hidden lg:flex ml-auto items-center">
            <div
              className="cursor-pointer ml-auto items-center text-primary-light"
              onClick={help}
            >
              <HelpNavIcon />
            </div>
            <div
              className="flex w-10 h-10 ml-5 bg-orange-40 rounded-full items-center 
              justify-center font-bold text-white text-sm"
            >
              <Profile />
            </div>
          </div>
        </div>

        <div
          className="lg:hidden cursor-pointer items-center text-primary-light"
          onClick={() => setIsShowing(!isShowing)}
        >
          <HamburgerIcon />
        </div>
      </div>

      {isShowing && (
        <div className="fixed lg:hidden w-full h-full bg-black/70 z-10 top-0"></div>
      )}
      <div
        className={`lg:hidden fixed w-11/12 md:w-9/12 h-full top-0 ${
          isShowing ? "z-20 block" : "-z-[20] hidden"
        }`}
      >
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 -translate-x-full"
          enterTo="opacity-100 translate-x-0"
          leave="transform duration-[400ms] transition ease-in-out"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-100 -translate-x-full"
        >
          <div className="h-full w-full bg-white p-4">
            <div className="flex h-[46px] items-center mb-4">
              <Link to="/">
                <img src={logo} alt="Menubly" className="max-h-[30px]" />
              </Link>
              <button className="ml-auto" onClick={() => closeDialogs()}>
                <DeleteIcon />
              </button>
            </div>
            <div className="border-t border-b border-neutral-10">
              <ul className="py-8">
                <li className="w-full mb-8" onClick={() => closeDialogs()}>
                  <NavItem name="Menus" url="/" childrenUrl="/places/:id" />
                </li>
                <li className="w-full mb-8" onClick={() => closeDialogs()}>
                  <NavItem name="Mini-website" url="/miniwebsite" />
                </li>
                <li className="w-full mb-8" onClick={() => closeDialogs()}>
                  <NavItem name="Analytics" url="/analytics" />
                </li>
                <li className="w-full mb-8" onClick={() => closeDialogs()}>
                  <NavItem
                    name="Orders"
                    url="/orders"
                    childrenUrl="/orders/:id"
                  />
                </li>
                <li className="w-full" onClick={() => closeDialogs()}>
                  <NavItem name="Items Collection" url="/items-collection" />
                </li>
              </ul>
            </div>
            <div className="mt-8">
              <div className="flex items-center space-x-4">
                <div className="flex w-10 h-10 bg-orange-40 rounded-full items-center justify-center font-bold text-white text-sm">
                  {auth?.user?.firstName?.charAt(0)}
                </div>
                <span className="text-neutral-80 text-base">
                  {auth?.user?.firstName}
                </span>
              </div>
              <div className="mt-8">
                <div className="w-full" onClick={() => closeDialogs()}>
                  <Link to="/account" className="text-neutral-60">
                    Account
                  </Link>
                </div>
                <div className="w-full mt-4">
                  <a
                    href="/billing"
                    rel="noreferrer"
                    className="text-neutral-60"
                  >
                    Billing
                  </a>
                </div>
                <div className="w-full mt-4">
                  <a
                    onClick={() => {
                      closeDialogs();
                      help();
                    }}
                    rel="noreferrer"
                    className="text-neutral-60"
                  >
                    Help
                  </a>
                </div>
              </div>
              <div className="mt-8 border-t border-neutral-10 pt-6">
                <div className="w-full">
                  <button
                    className="text-default-error"
                    onClick={() => {
                      auth.logout();
                      setIsShowing(false);
                    }}
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
};

export default NavTop;
