import React, { useEffect, useState } from "react";
import H2 from "components/headings/H2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "contexts/Auth/AuthContext";
import "react-tooltip/dist/react-tooltip.css";
import { useForm } from "react-hook-form";
import { UserProfileType } from "types/Auth.type";
import { ButtonDefault, ButtonPrimary, Input, Label } from "components/form";
import { Spinner } from "components/spinner/Spinner";
import AuthService from "services/auth.service";
import PaymentAccountService from "services/paymentAccount.service";
import customToast from "components/Toast/ToastSuccess";
import { LoadingIndicator } from "components/spinner/LoadingIndicator";
import { Tab } from "@headlessui/react";
import StripeImage from "assets/images/stripe.png";
import Check from "assets/images/check.png";
import Cross from "assets/images/cross.png";
import { Modal } from "antd";
import "./accountPage.scss";
import { PaymentAccountStatus } from "types/PaymentAccount.type";
import { AppCombobox } from "components/form/AppCombobox";

const AccountPage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tab = searchParams?.get("tab");
  const connected = searchParams?.get("connected");

  const auth = useAuth();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    formState: { errors, isValid, isDirty },
    reset,
  } = useForm<UserProfileType>({
    defaultValues: {
      firstName: auth?.user?.firstName,
      timeZone: auth?.user?.timeZone,
      email: auth?.user?.email,
    },
    mode: "onChange",
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [connectedAccountStatus, setConnectedAccountStatus] =
    useState<PaymentAccountStatus>();
  const [openNotification, setOpenNotification] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoading(false);

      PaymentAccountService.getStatus().then((result) => {
        setConnectedAccountStatus(result.data);
        if (connected && result) {
          setOpenNotification(true);
          tabChange(1);
        }

        auth.update({
          paymentAccountId: result.data.id,
          paymentAccountStatus: result.data.status,
        });
      });
    }, 500);
  }, []);

  const moment = require("moment-timezone");
  const defaultTimezone = "UTC";
  useEffect(() => {
    setValue("firstName", auth?.user?.firstName);
    setValue("timeZone", auth?.user?.timeZone || defaultTimezone);
    setValue("email", auth?.user?.email);
    reset(getValues());
  }, []);

  useEffect(() => {
    const m = moment();

    const timezones = [];
    var timezone_names = moment.tz.names().filter((c: string) => !c.includes("GMT"));
    for (var i = 0; i < timezone_names.length; i++) {
      var timezone_zones = m.tz(timezone_names[i]).format("Z");
      timezones.push({
        value: timezone_names[i],
        name: `${timezone_names[i]} (${timezone_zones})`,
      });
    }
    setTimeZones(timezones);
  }, []);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await AuthService.updateProfileByUserId(auth.user.userId, data);
      auth.update({
        firstName: data.firstName,
        timeZone: data.timeZone,
        userId: auth.user.userId,
        email: auth?.user?.email,
      });
      setLoading(false);
      customToast.success(`Your account is updated successfully`);
    } catch {
      setLoading(false);
      customToast.error(`Your account is updated failure`);
    }
  };

  useEffect(() => {
    switch (tab) {
      case "profile":
        setSelectedTabIndex(0);
        break;
      case "stripe":
        setSelectedTabIndex(1);
        break;
      default:
        setSelectedTabIndex(0);
    }
  }, [tab]);

  const tabChange = (index: number) => {
    if (index == 0) {
      navigate(`/account?tab=profile`);
    }
    if (index == 1) {
      navigate(`/account?tab=stripe`);
    }
  };

  const registerStripeAccount = async () => {
    setLoading(true);
    try {
      const paymentAccountUrl = window.location.href;
      const registerUrl = await PaymentAccountService.createConnectedLink({
        refreshUrl: paymentAccountUrl,
        returnUrl: `${paymentAccountUrl}&connected=true`,
      });
      window.location.href = registerUrl.data;
    } catch {
      setLoading(false);
    }
  };

  const goToDashBoard = async () => {
    if (connectedAccountStatus?.status === "Restricted") {
      registerStripeAccount();
      return;
    }

    window.open("https://dashboard.stripe.com", "_blank");
  };

  const [timeZones, setTimeZones] = useState<any>([]);

  return pageLoading ? (
    <LoadingIndicator></LoadingIndicator>
  ) : (
    <>
      <div className="accountPage px-4 md:px-0">
        <H2>Account</H2>
        <div className="w-full lg:w-3/4 mt-[29px] tabs lg:pr-4">
          <Tab.Group selectedIndex={selectedTabIndex} onChange={tabChange}>
            <Tab.List className="flex">
              <Tab className="font-bold text-base">Profile</Tab>
              <Tab className="font-bold text-base">Stripe Connect</Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <div className="flex items-center my-[35px] px-4 lg:px-0">
                  <form
                    className="w-full md:max-w-[320px]"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="mb-[29px]">
                      <Label name="Name" isRequired={true} />
                      <Input
                        control={control}
                        name="firstName"
                        rules={{
                          required: "This is required.",
                        }}
                        placeholder="Type your name here"
                        type="text"
                      />
                    </div>
                    <div className="mb-[29px]">
                      <Label name="Email" isRequired={true} />
                      <Input
                        control={control}
                        name="email"
                        disabled={true}
                        rules={{
                          required: "This is required.",
                        }}
                        placeholder="Type your name here"
                        type="text"
                      />
                    </div>
                    <div className="mb-[29px]">
                      <Label name="Timezone" isRequired={true} />
                      {
                        <AppCombobox
                          data={timeZones}
                          selectDefault={getValues("timeZone")}
                          onSelected={(data: { value: any }) => {
                            setValue("timeZone", data?.value, {
                              shouldDirty: true,
                            });
                            trigger("timeZone");
                          }}
                        />
                      }
                    </div>
                    <div className="mt-6 flex">
                      <ButtonPrimary
                        size="small"
                        type="submit"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        disabled={!isDirty}
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>{(loading && "Please wait...") || "Save"}</span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  </form>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="flex flex-col lg:w-[480px] px-4 lg:px-0">
                  <div>
                    <span className="font-bold text-lg">
                      Connect to your Stripe account
                    </span>
                  </div>
                  <div className="mt-4">
                    <img src={StripeImage} alt="" className="w-auto" />
                  </div>
                  {!auth?.user?.paymentAccountId ? (
                    <div className="text-base mt-4 text-justify">
                      We use Stripe to handle payments. Connect to your Stripe
                      account to start receiving payments.
                    </div>
                  ) : (
                    <>
                      <div className="text-base mt-4 text-justify">
                        Your Stripe account has been connected to Menubly.
                      </div>
                      {!auth.isStripeAccountConnected() && (
                        <>
                          <div className="text-justify">
                            <span style={{ color: "red" }}>
                              However, you have not completed setting up your
                              Stripe account to receive payment.
                            </span>
                            <span>
                              &nbsp;Please complete your setup on Stripe to
                              start receiving payment from your customers.
                            </span>
                          </div>
                          <div className="text-base mt-4 text-justify">
                            Having trouble setting up? Check out{" "}
                            <a
                              href="https://support.stripe.com"
                              target="_blank"
                              rel="noreferrer"
                              className="underline text-black"
                            >
                              Stripe Support
                            </a>{" "}
                            or email us at support@menubly.com
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {!auth?.user?.paymentAccountId ? (
                    <div className="flex justify-between mt-4">
                      <a className="mt-auto mb-auto" href="https://stripe.com/">
                        What is Stripe?
                      </a>
                      <ButtonPrimary
                        size="small"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        onClick={() => registerStripeAccount()}
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>
                            {(loading && "Please wait...") ||
                              "Connect to Stripe"}
                          </span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  ) : (
                    <div className="flex justify-between mt-4">
                      <div></div>
                      <ButtonPrimary
                        size="small"
                        styles="ml-auto md:ml-0 lg:ml-auto"
                        onClick={() =>
                          auth.isStripeAccountConnected() ||
                          auth.isStripeAccountCompletedOnboarding()
                            ? goToDashBoard()
                            : registerStripeAccount()
                        }
                      >
                        <div className="flex items-center justify-center">
                          {loading && <Spinner />}
                          <span>
                            {(loading && "Please wait...") ||
                              (auth.isStripeAccountConnected()
                                ? "Manage Stripe Settings"
                                : "Continue Setting up Stripe")}
                          </span>
                        </div>
                      </ButtonPrimary>
                    </div>
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
      <Modal
        className="stripe-connect"
        title={
          auth.isStripeAccountConnected()
            ? "Stripe connection successful!"
            : "Almost there!"
        }
        onCancel={() => setOpenNotification(false)}
        open={
          openNotification &&
          (auth.isStripeAccountCompletedOnboarding() ||
            auth.isStripeAccountConnected())
        }
        footer={[
          <ButtonPrimary
            key="guide"
            onClick={() =>
              auth.isStripeAccountConnected()
                ? window.open(
                    "https://menubly.usetiful.help/article/0191740d-c1c7-6606-ce5a-67bc98c1a019",
                    "_blank"
                  )
                : goToDashBoard()
            }
          >
            {auth.isStripeAccountConnected()
              ? "Go to Stripe Payment Setting Guides"
              : "Finish Setting up Stripe"}
          </ButtonPrimary>,
          <ButtonDefault key="close" onClick={() => setOpenNotification(false)}>
            {auth.isStripeAccountConnected() ? "Close" : "Do it later"}
          </ButtonDefault>,
        ]}
      >
        <div className="my-8">
          <img src={Check} alt="" className="w-auto mx-auto" />
        </div>
        <p className="antd-model-desc">
          {auth.isStripeAccountConnected() ? (
            "Connection successful! Your payment gateway with Stripe is now set up and ready to process transactions."
          ) : (
            <>
              <span>
                You have linked your Stripe account successfully to Menubly.
              </span>
              <br />
              <span>
                However, it seems you still need to fill in a bit more
                information in Stripe Dashboard to start getting payments.
              </span>
            </>
          )}
        </p>
      </Modal>
      <Modal
        className="stripe-connect"
        title="Fail to set up your Stripe Connect!"
        onCancel={() => setOpenNotification(false)}
        open={openNotification && auth.isStripeAccountRestricted()}
        footer={[
          <ButtonPrimary
            key="guide"
            onClick={() =>
              auth.isStripeAccountCompletedOnboarding()
                ? goToDashBoard()
                : registerStripeAccount()
            }
          >
            {loading && <Spinner />}
            <span>{(loading && "Please wait...") || "Try again"}</span>
          </ButtonPrimary>,
          <ButtonDefault key="close" onClick={() => setOpenNotification(false)}>
            Try later
          </ButtonDefault>,
        ]}
      >
        <div className="my-8">
          <img src={Cross} alt="" className="w-auto mx-auto" />
        </div>
        <p className="antd-model-desc">
          Your setup with Stripe Connect hasn&apos;t been completed. Please try
          again or email{" "}
          <a href="mailto:support@menubly.com">support@menubly.com</a> for help
          if the issue still persists.
        </p>
      </Modal>
    </>
  );
};

export default AccountPage;
