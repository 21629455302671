import React, { useEffect, useState } from "react";
import NavTop from "layouts/NavTop";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Alert from "layouts/Alerts/Alerts";
import { useAuth } from "contexts/Auth/AuthContext";
import UserSubscriptionService from "services/userSubscription.service";
import _ from "lodash";
import Connector from "signalr-connection";
import NotificationSound from "assets/notification-sound.mp3";

export interface MasterLayoutProps {
  type: string;
  className?: string;
}

const MasterLayout: React.FC<MasterLayoutProps> = ({
  type,
  className,
}: MasterLayoutProps) => {
  const auth = useAuth();
  const [showAlert, setShowAlert] = useState(true);
  const [subscription, setSubscription] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();
  const handleAlertClose = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    const marginTopStyle = showAlert
      ? { marginTop: "50px" }
      : { marginTop: "" };
    document.getElementById("main-wrapper")!.style.marginTop =
      marginTopStyle.marginTop;
  }, [showAlert]);

  const { joinGroup, setToken, subscribe } = Connector();

  const playNotificationSound = () => {
    const audio = new Audio(NotificationSound);
    audio.muted = false;
    audio.play();
  };

  const debouncePlayNotificationSound = _.debounce(playNotificationSound, 1000);

  subscribe("MasterLayout", "OrderCreated", (_, data) => {
    debouncePlayNotificationSound();
  });

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const state = searchParams.get("state");
        if (state == "succeeded") {
          const planId = searchParams.get("plan_id") || "";
          const response = await UserSubscriptionService.getUpdatedSubscription(
            auth.getCurrentUser()?.userId,
            planId
          );
          if (response?.data) {
            localStorage.setItem("subscription", JSON.stringify(response.data));
          }
        } else {
          const subscription = localStorage.getItem("subscription");
          const subscriptionData = subscription
            ? JSON.parse(subscription)
            : null;
          const storedAt =
            Number.parseInt(
              localStorage.getItem("subscription_stored_at") || "0"
            ) || 0;

          // Reset cache after 1 day
          if (
            !subscriptionData ||
            Date.now() - storedAt > 24 * 60 * 60 * 1000
          ) {
            const response = await UserSubscriptionService.getSubscription(
              auth.getCurrentUser()?.userId
            );
            if (response?.data) {
              setSubscription({ ...response.data });
              localStorage.setItem(
                "subscription",
                JSON.stringify(response.data)
              );
              localStorage.setItem(
                "subscription_stored_at",
                Date.now().toString()
              );
            }
          } else {
            setSubscription({ ...subscriptionData });
          }
        }
      } catch (error) {
        console.log("Error fetching payment link:", error);
      }
    };

    if (isAuthenticated) {
      fetchSubscription();

      // Configure websocket
      setToken(auth.user.accessToken);
      joinGroup(auth.user.userId);
    }
  }, [isAuthenticated, location]);

  useEffect(() => {
    setIsAuthenticated(!!auth.user);
  }, [auth.user]);

  document.addEventListener("trix-before-paste", function (e: any) {
    if (e.paste.hasOwnProperty("html")) {
      e.paste.html = _.replace(e.paste.html, "\r\n</body>\r\n", "</body>");
      e.paste.html = _.replace(e.paste.html, "\r\n<body>\r\n", "<body>");
    }
  });

  return (
    <>
      <div>
        {showAlert && subscription && (
          <Alert subscription={subscription} onClose={handleAlertClose} />
        )}
      </div>
      <div className={` ${className} bg-[#F0F2F6] min-h-[calc(100vh)]`}>
        <div
          className={`w-full block sticky top-0 z-[998]`}
          id="navtop-wrapper"
        >
          <NavTop />
        </div>
        <div className="flex md:px-4">
          <main className="w-full mt-0" id="main-wrapper">
            <Outlet />
          </main>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default MasterLayout;
