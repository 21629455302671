export enum STATUS  {
    'Hidden' = 0,
    'Sold out' = 1,
    'Active' = 2,
}

export enum SOLD_STATUS  {
    'Pending' = 0,
    'Confirmed' = 1,
    'Fulfilled' = 2,
    'Cancelled' = 3
}

export enum PAYMENT_STATUS  {
    'Unpaid' = 0,
    'Partially Paid' = 1,
    'Paid' = 2,
    'Refunded' = 3
}

export enum ORDER_TYPE  {
    'Dine-in' = 0,
    'Online' = 1
}

export enum OPTION_STATUS  {
    'In Stock' = 0,
    'Sold out' = 1,
    'Hidden' = 2,
}

export enum INVOICE_STATUS  {
    'UnKnown' = 0,
    'Paid' = 1,
    'Posted' = 2,
    'PaymentDue' = 3,
    'NotPaid' = 4,
    'Voided' = 5,
    'Pending' = 6,
}

export enum PRICING_TYPE  {
    "Fixed Price" =  0,
    "Show as Discount" = 1,
    "Hide Price" = 2,
    "Show as Custom Text" =3
}
