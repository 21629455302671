import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Orders } from "../order/orderSlice";

export interface Item {
  id?: string | number;
  menuItemId: string;
  name: string;
  orderId: number;
  price: number;
  quantity?: number;
  total?: number;
}

export interface Customer {
  createdDate: string;
  id: string;
  customerName: string;
  customerPhone: string;
  numberOfOrders: number;
  orders?: Orders[];
}

export interface CustomersState {
  customers: Customer[];
  totalItems?: number | null;
  totalPages?: number | null;
  isLoading: boolean;
  customerDetails: Customer | null;
}

const initialState: CustomersState = {
  customers: [],
  totalItems: null,
  totalPages: null,
  isLoading: false,
  customerDetails: null,
};

export const CustomersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomers: (
      state,
      action: PayloadAction<{
        data: Customer[];
        totalItems: number;
        totalPages: number;
      }>
    ) => {
      return {
        ...state,
        customers: action.payload.data,
        totalItems: action.payload.totalItems,
        totalPages: action.payload.totalPages,
      };
    },
    setCustomerDetails: (state, action: PayloadAction<Customer>) => {
      return {
        ...state,
        customerDetails: action.payload,
      };
    },
  },
});

export const { setCustomers, setCustomerDetails } = CustomersSlice.actions;

export default CustomersSlice.reducer;
