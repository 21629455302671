import * as signalR from "@microsoft/signalr";

interface INotificationReceivedEvent {
  (type: string, data: any): void;
  type?: string;
  source?: string;
}

class Connector {
  private connection?: signalR.HubConnection;
  private connectionBuilder?: signalR.HubConnectionBuilder;
  public events: INotificationReceivedEvent[];
  static instance: Connector;
  constructor() {
    this.events = [] as any;
  }
  public subscribe = (
    source: string,
    type: string,
    event: INotificationReceivedEvent
  ) => {
    this.events = this.events.filter(
      (x) => !(x.source === source && x.type === type)
    );
    event.type = type;
    event.source = source;
    this.events.push(event);
  };
  public setToken = (token: string) => {
    this.connectionBuilder = new signalR.HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HUB_ENDPOINT ?? "", {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect();
  };
  public joinGroup = (groupName: string) => {
    this.connection = this.connectionBuilder!.build();
    this.connection
      .start()
      .then(() => {
        this.connection!.send("joinGroup", groupName).then((x) =>
          console.log("Join hub successfully!")
        );
        this.connection!.on("notificationReceived", (type, data) => {
          this.events?.forEach((e) => {
            if (e.type === type) e(type, data);
          });
        });
      })
      .catch((err) => {
        console.error(err)
      });
  };
  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}
export default Connector.getInstance;
