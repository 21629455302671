import React from "react";

export const Vector = () => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66667 3.49984C4.20643 3.49984 3.83333 3.87293 3.83333 4.33317C3.83333 4.79341 4.20643 5.1665 4.66667 5.1665H11.3333C11.7936 5.1665 12.1667 4.79341 12.1667 4.33317C12.1667 3.87293 11.7936 3.49984 11.3333 3.49984H4.66667Z" fill="currentColor" />
            <path d="M3.83333 7.6665C3.83333 7.20627 4.20643 6.83317 4.66667 6.83317H11.3333C11.7936 6.83317 12.1667 7.20627 12.1667 7.6665C12.1667 8.12674 11.7936 8.49984 11.3333 8.49984H4.66667C4.20643 8.49984 3.83333 8.12674 3.83333 7.6665Z" fill="currentColor" />
            <path d="M4.66667 10.1665C4.20643 10.1665 3.83333 10.5396 3.83333 10.9998C3.83333 11.4601 4.20643 11.8332 4.66667 11.8332H8C8.46024 11.8332 8.83333 11.4601 8.83333 10.9998C8.83333 10.5396 8.46024 10.1665 8 10.1665H4.66667Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.16667 0.166504C1.24619 0.166504 0.5 0.912696 0.5 1.83317V15.1665C0.5 16.087 1.24619 16.8332 2.16667 16.8332H13.8333C14.7538 16.8332 15.5 16.087 15.5 15.1665V1.83317C15.5 0.912696 14.7538 0.166504 13.8333 0.166504H2.16667ZM13.8333 1.83317H2.16667L2.16667 15.1665H9.3018L13.8333 10.2573V1.83317ZM13.8333 15.1665H11.57L13.8333 12.7145V15.1665Z" fill="currentColor" />
        </svg>

    )
}