
import { AUTH, HOST_URL } from "../constant/api.constant";
import axiosInstance from "./restful.service";

const searchUsersByEmail = (emailPrefix: string) => {
    return axiosInstance.get(HOST_URL + AUTH.SEARCH_USERS(emailPrefix));
}

const searchPartnerUsersByEmail = (partnerEmail: string, emailPrefix: string) => {
    return axiosInstance.get(HOST_URL + AUTH.SEARCH_PARTNER_USERS(partnerEmail, emailPrefix));
}

const getUserRequests = (userId: string) => {
    return axiosInstance.get(HOST_URL + AUTH.REQUESTS(userId) + `?requestType=2`);
}

const createUserRequest = (userId: string, payload: any) => {
    return axiosInstance.post(HOST_URL + AUTH.REQUESTS(userId), payload);
}

const UserService = {
    searchUsersByEmail,
    createUserRequest,
    getUserRequests,
    searchPartnerUsersByEmail
};

export default UserService;