import React, { useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import {
  DishIcon,
  InvoiceIcon,
  IphoneIcon,
  LocationIcon,
  SearchIcon,
  Vector,
} from "assets/icons";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Currencies } from "constant/currencies";
import PlaceService from "services/places.service";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import soldOut from "assets/images/sold-out.png";
import { ImageContent } from "components/Image/Image";
import { Helmet } from "react-helmet";
import { FormatCurrency, convert_vi_to_en } from "utils";
import { PlaceCategory } from "store/slices/places/placeSlice";
import { CartCounterAmountToggle } from "pages/Places/component/CartCounterAmountToggle";
import H4 from "components/headings/H4";
import { useCartContext } from "../../contexts/cart/cart_context";
import menuDefault from "assets/images/menu.png";

const PreviewPage = () => {
  const { cart, getCartItems } = useCartContext();
  const navigate = useNavigate();
  const { name, id } = useParams();
  const [searchParams] = useSearchParams();
  const [itemPreview, setItemPreview] = useState<any>();
  const [inforAndBrand, setInforAndBrand] = useState<any>();
  const [colorThemes, setColorThemes] = useState("#30A9A6");
  const [loading, setLoading] = useState(true);
  const counterRef = useRef<any>(null);
  const [clickedButton, setOrderMenuClickedButton] = useState("menu");
  const [cartItems, setCartItems] = useState<any>([]);
  const [isCartToggleDisabled, setIsCartToggleDisabled] = useState(false);

  useEffect(() => {
    const getPlace = async () => {
      try {
        const response = await PlaceService.getPlacesPublic(name);
        if (response?.data) {
          if (!response.data.themeColor) {
            response.data.themeColor = colorThemes;
          }
          setInforAndBrand(response.data);
          setItemPreview(response.data?.categories[0]);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };
    if (name) {
      getPlace();
    }
  }, [name]);

  useEffect(() => {
    if (inforAndBrand?.id) {
      setCartItems(getCartItems(inforAndBrand?.id));
    }
  }, [cart, inforAndBrand]);

  useEffect(() => {
    if (id && inforAndBrand?.categories) {
      setItemPreview(
        inforAndBrand?.categories.filter((item: any) => item.id === id)[0]
      );
    }
  }, [inforAndBrand, id]);

  useEffect(() => {
    if (inforAndBrand?.themeColor) {
      setColorThemes(inforAndBrand?.themeColor);
    }
  }, [inforAndBrand]);

  const [scrollTop, setScrollTop] = useState(0);
  const [positionCategories, setPositionCategories] = useState<any>();

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    setPositionCategories(
      document?.getElementById("categoriesList")?.offsetTop || 0
    );
    document.body.classList.add("previewPage");

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.body.classList.remove("previewPage");
    };
  }, []);

  const handleScroll = (event: any) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  const setSearchedItem = (keyword: any) => {
    let newCategories: PlaceCategory[] = [];
    if (inforAndBrand) {
      inforAndBrand.categories?.map((item: PlaceCategory) => {
        let menuItemSearch = item.menuItems?.filter(
          (menu) =>
            convert_vi_to_en(menu?.name)?.indexOf(keyword) != -1 ||
            menu?.name?.toLowerCase()?.indexOf(keyword?.toLowerCase()) != -1 ||
            menu?.name?.toLowerCase()?.includes(keyword?.toLowerCase())
        );
        if (menuItemSearch?.length > 0) {
          newCategories.push({
            ...item,
            menuItems: menuItemSearch,
            expanded: true,
          });
        }
      });
      if (keyword == "") {
        newCategories = inforAndBrand.categories;
        newCategories = inforAndBrand.categories;
      }
      setItemPreview(newCategories[0]);
    }
  };
  const orderButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const button: HTMLButtonElement = event.currentTarget;
    setOrderMenuClickedButton(button.name);
  };
  const handleCartUpdate = (
    id: any,
    count: number,
    infoAndBrand?: any,
    currencySign?: any
  ) => {
    const updatedCartItems = [...cartItems];
    const index = updatedCartItems.findIndex((item) => item.id === id);
    if (index !== -1) {
      updatedCartItems[index].count = count;
    } else {
      updatedCartItems.push({ id, count, infoAndBrand, currencySign });
    }
    setCartItems(updatedCartItems);
  };
  useEffect(() => {
    setIsCartToggleDisabled(!inforAndBrand?.orderEnabled);
  }, [inforAndBrand]);

  return (
    <>
      {/* h-[calc(100vh)] overflow-y-auto */}
      {clickedButton == "menu" && (
        <div
          className="preview  hiddenScroll"
          onScroll={handleScroll}
          style={{ background: inforAndBrand?.backgroundColor || "#ffffff" }}
        >
          <Helmet>
            {loading ? "" : <title> {inforAndBrand?.name} - Menubly</title>}
            {loading ? (
              ""
            ) : (
              <meta
                name="description"
                content={
                  inforAndBrand!.note ||
                  inforAndBrand?.phoneNumber + inforAndBrand?.address ||
                  `Check out ${inforAndBrand?.name}'s menu on Menubly`
                }
              />
            )}
          </Helmet>
          <div
            className="w-full h-full pb-10"
            style={{ background: inforAndBrand?.headerColor || "#30A9A6" }}
          >
            <div
              className="flex items-center justify-center h-[180px] bg-cover bg-center"
              style={{
                backgroundImage: `url("${inforAndBrand?.headerImage}")`,
              }}
            >
              {inforAndBrand?.logo && (
                <img
                  src={inforAndBrand?.logo}
                  alt=""
                  className="w-[88px] h-[88px]"
                />
              )}
            </div>
            <div
              className="py-4 md:py-6 lg:py-6 px-4 -mt-10 lg:px-[45px] w-full h-full rounded-[40px] rounded-b-none "
              style={{
                background: inforAndBrand?.backgroundColor || "#ffffff",
              }}
            >
              <div
                className="main-header"
                style={{
                  color: inforAndBrand?.textColor || "#000",
                  background: inforAndBrand?.backgroundColor || "#ffffff",
                }}
              >
                <div>
                  <h1
                    className="text-black text-[22px] lg:text-[34px]"
                    style={{
                      color: inforAndBrand?.textColor || "#000",
                    }}
                  >
                    {loading ? (
                      <div className="animate-pulse h-6 w-1/2 lg:w-1/3 bg-neutral-30 rounded-full"></div>
                    ) : (
                      inforAndBrand?.name
                    )}
                  </h1>
                  <div className="flex flex-wrap mt-4 text-sm md:text-lg">
                    {inforAndBrand?.address && (
                      <div className="flex  text-neutral-80 mr-4 lg:mr-10">
                        {loading ? (
                          <div className="animate-pulse h-[20px] w-[100px] bg-neutral-30 rounded-full"></div>
                        ) : (
                          <>
                            <span
                              className="mr-1.5 md:mt-1"
                              style={{ color: colorThemes }}
                            >
                              <LocationIcon />
                            </span>
                            <span
                              style={{
                                color: inforAndBrand?.textColor || "#000",
                              }}
                            >
                              {inforAndBrand?.address || "Your address here"}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                    {inforAndBrand?.phoneNumber && (
                      <div className="flex text-neutral-80  mr-4 lg:mr-10">
                        {loading ? (
                          <div className="animate-pulse h-[20px] w-[100px] bg-neutral-30 rounded-full"></div>
                        ) : (
                          <>
                            <span
                              className=" mr-1.5 md:mt-1"
                              style={{ color: colorThemes }}
                            >
                              <IphoneIcon width={20} />
                            </span>
                            <span
                              style={{
                                color: inforAndBrand?.textColor || "#000",
                              }}
                            >
                              {inforAndBrand?.phoneNumber || "123456789"}
                            </span>
                          </>
                        )}
                      </div>
                    )}

                    <div className="hidden lg:flex text-neutral-80  mt-4 lg:mt-0">
                      {loading ? (
                        <div className="animate-pulse h-[20px] w-[100px] bg-neutral-30 rounded-full"></div>
                      ) : (
                        <>
                          <span
                            className="mr-[8.5px] mt-[5px]"
                            style={{ color: colorThemes }}
                          >
                            <Vector />
                          </span>
                          <span
                            style={{
                              color: inforAndBrand?.textColor || "#000",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  inforAndBrand?.note
                                    ?.replaceAll("\n", "<br />")
                                    .toString() || "Any note here",
                              }}
                            ></span>
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className="block text-sm md:text-lg lg:hidden items-center mt-4"
                    style={{
                      color: inforAndBrand?.textColor || "#000",
                    }}
                  >
                    {loading ? (
                      <div className="animate-pulse h-[20px] w-[100px] bg-neutral-30 rounded-full"></div>
                    ) : (
                      <>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              inforAndBrand?.note
                                ?.replaceAll("\n", "<br />")
                                .toString() || "Any note here",
                          }}
                        ></span>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="pt-[22px]"
                  style={{
                    background: inforAndBrand?.backgroundColor || "#ffffff",
                  }}
                >
                  <div
                    className="border border-primary-light rounded overflow-hidden flex"
                    style={{ borderColor: colorThemes }}
                  >
                    <button
                      className="flex items-center justify-center px-2 bg-white"
                      style={{
                        background: inforAndBrand?.backgroundColor || "#ffffff",
                        borderColor: colorThemes,
                      }}
                    >
                      <span
                        style={{ color: colorThemes }}
                        className="flex-none"
                      >
                        <SearchIcon />
                      </span>
                    </button>
                    <input
                      type="text"
                      style={{
                        background: inforAndBrand?.backgroundColor || "#ffffff",
                        color: inforAndBrand?.textColor || "#000",
                        borderColor: colorThemes,
                        font: colorThemes,
                      }}
                      className="px-1 py-2 w-11/12 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
                      placeholder="Search"
                      onChange={(event) => setSearchedItem(event.target.value)}
                    />
                  </div>
                </div>
                <div
                  id="categoriesList"
                  className="mt-6 lg:mt-10 overflow-x-auto whitespace-nowrap pb-4 mb-4 hiddenScroll2 flex w-full"
                >
                  {loading ? (
                    <div className="flex space-x-2">
                      {[1, 2, 3].map((index) => {
                        return (
                          <>
                            <div
                              key={"item_loading_" + index}
                              className="animate-pulse h-[38px] w-[135px] bg-neutral-30 rounded-full"
                            ></div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    inforAndBrand?.categories?.map((item: any) => {
                      return (
                        <h2
                          onClick={() => navigate(`/p/${name}/${item?.id}`)}
                          // type="button"
                          key={item.id}
                          style={{
                            backgroundColor:
                              itemPreview?.id == item.id ? colorThemes : "",
                            borderColor: colorThemes,
                            color:
                              itemPreview?.id == item.id
                                ? "#fff"
                                : colorThemes
                                ? colorThemes
                                : "#30A9A6",
                            display: "inline-table",
                          }}
                          className={`font-semibold text-sm text-primary-light border rounded-[60px] px-[14px] py-[8px] mr-2 cursor-pointer inline
                                         ${
                                           itemPreview?.id == item.id &&
                                           "text-white"
                                         }
                                         `}
                        >
                          {item?.name}
                        </h2>
                      );
                    })
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-4 lg:-mx-[22px] pb-10">
                {loading
                  ? [1, 2, 3, 4].map((index) => (
                      <div
                        key={"skeleton_list_" + index}
                        className="w-full md:w-1/2 lg:w-1/4  px-4 lg:px-[22px] mb-8"
                      >
                        <div className="">
                          <div
                            className="animate-pulse h-[28px] w-full bg-neutral-30 rounded-[8px]"
                            style={{ paddingTop: "60%" }}
                          ></div>
                        </div>
                        <div className="animate-pulse h-[16px] w-[80px] bg-neutral-30 rounded-[8px] mt-2"></div>
                        <div className="animate-pulse h-[16px] w-[50px] bg-neutral-30 rounded-[8px] mt-2"></div>
                      </div>
                    ))
                  : itemPreview?.menuItems?.map((menu: any) => {
                      return (
                        menu?.status != 0 && (
                          <div
                            key={menu?.id}
                            className="mb-6 w-full md:w-1/2 lg:w-1/4 px-4 lg:px-[22px]"
                          >
                            {menu?.image && (
                              <div className="relative rounded overflow-hidden mb-2">
                                <LazyLoadComponent delayTime={500}>
                                  <ImageContent src={menu?.image} />
                                  {inforAndBrand?.orderEnabled == true && (
                                    <CartCounterAmountToggle
                                      key={menu?.id}
                                      id={String(menu?.id) ?? ""}
                                      label={menu?.name}
                                      inforAndBrand={menu}
                                      currencySign={inforAndBrand?.currency}
                                      onClick={handleCartUpdate}
                                      disabled={isCartToggleDisabled}
                                      parentClassName="parent-pill-menu"
                                      placeId={inforAndBrand?.id ?? ""}
                                      count={
                                        cartItems.find(
                                          (item: any) =>
                                            item.id == String(menu?.id)
                                        )?.amount
                                      }
                                    />
                                  )}
                                </LazyLoadComponent>
                                {/* <img src={menu?.image || menuDefault} alt="" className="w-full" /> */}
                                {menu?.status == 1 && (
                                  <div className="absolute flex items-center justify-center rounded bg-black/50 top-0 left-0 w-full h-full">
                                    <img
                                      src={soldOut}
                                      alt=""
                                      className="w-auto max-w-[119px]"
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            <h3
                              className="font-bold  text-[#000] text-base lg:text-sm"
                              style={{
                                color: inforAndBrand?.textColor || "#000",
                              }}
                            >
                              {menu?.name}
                            </h3>
                            <p
                              className="body-small mt-0.5"
                              style={{
                                color: inforAndBrand?.textColor || "#000",
                              }}
                            >
                              {menu?.description}
                            </p>
                            <p
                              className="font-bold text-sm mt-0.5"
                              style={{
                                color: inforAndBrand?.textColor || "#000",
                              }}
                            >
                              {FormatCurrency(
                                menu?.price,
                                inforAndBrand?.currency
                              )}
                              {/* <sup>{inforAndBrand?.currency ? Currencies.find((item: any) => item.code == (inforAndBrand?.currency || 'USD'))?.symbol : '$'}</sup> */}
                            </p>
                          </div>
                        )
                      );
                    })}
                {itemPreview?.menuItems?.length <= 0 && (
                  <p
                    className="py-8 w-full text-center"
                    style={{
                      color: inforAndBrand?.textColor || "#000",
                    }}
                  >
                    No items found.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {clickedButton == "order" && (
        <div className="p-3">
          <div className="px-0 py-5">
            <H4 className="grid grid-cols-3">
              Your Order
              <span
                className="h-6 w-1/3 rounded-full text-center leading-normal"
                style={{
                  borderColor: colorThemes,
                  backgroundColor: colorThemes,
                  color: inforAndBrand?.backgroundColor,
                }}
              >
                {" "}
                {cartItems.length}{" "}
              </span>
            </H4>
          </div>
          {cartItems.length > 0 &&
            cartItems?.map((cartItem: any, index: number) => (
              <div key={cartItem.id} className="px-0 py-5">
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <img src={menuDefault} alt="your order image"></img>
                  </div>
                  <div className="col-start-2 col-span-3 ... relative">
                    <p className="font-medium overflow-ellipsis overflow-hidden text-[22px] line-clamp-3">
                      {cartItem.name}{" "}
                    </p>
                    <div className="font-medium px-0 py-2">
                      {FormatCurrency(cartItem?.price, cartItem?.currencySign)}{" "}
                    </div>
                    <div>
                      <CartCounterAmountToggle
                        key={cartItem?.id}
                        id={String(cartItem?.id) ?? ""}
                        label={cartItem?.name}
                        inforAndBrand={cartItem}
                        currencySign={cartItem?.currencySign}
                        onClick={handleCartUpdate}
                        disabled={isCartToggleDisabled}
                        placeId={inforAndBrand?.id ?? ""}
                        parentClassName="parent-pill-order"
                        count={cartItem?.amount}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default PreviewPage;
