import React, {
  createContext,
  useReducer,
  useContext,
  ProviderProps,
  useEffect,
} from "react";
import reducer from "../../reducer/cartReducer";
const CartContext = createContext(undefined as any);

const getLocalCartData = () => {
  let localCartData = localStorage.getItem("cart");
  if (localCartData === null) {
    return [];
  } else {
    try {
      return JSON.parse(localCartData);
    } catch (error) {
      return [];
    }
  }
};
const initialState = {
  // cart: [],
  cart: getLocalCartData(),
  totalItems: 0,
  totalAmount: 0,
  currencySign: "",
};

const CartProvider = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addToCart = (
    id?: any,
    amount?: any,
    product?: any,
    currencySign?: any,
    placeId?: string
  ) => {
    if (product) {
      dispatch({
        type: "ADD_TO_CART",
        payload: { id, amount, product, currencySign, placeId },
      });
    }
  };

  const getCartItems = (placeId: string) => {
    let items = state.cart.filter((item: any) => item.placeId == placeId);
    return items;
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(state.cart));
  }, [state.cart]);

  return (
    <CartContext.Provider value={{ ...state, addToCart, getCartItems }}>
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return useContext(CartContext);
};
export { CartProvider, useCartContext };
