import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { WarningErrorIcon, WarningTriangleIcon } from "assets/icons/Warning";
import { AlertIcon } from "assets/icons";
import { formatTrialEndDate, getSubscription } from "utils";
interface AlertProps {
  subscription: any;
  onClose: () => void;
}
const Alert = ({ subscription, onClose }: AlertProps) => {
  const [showAlert, setShowAlert] = React.useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const { subscriptionState } = getSubscription();
  useEffect(() => {
    if (subscriptionState === "in_trial") {
      setShowAlert(true);
      setIsExpired(false);
    } else if (subscriptionState === "active") {
      setShowAlert(false);
      setIsExpired(false);
    } else {
      setShowAlert(true);
      setIsExpired(true);
      if (
        !redirected &&
        window.location.pathname !== "/home" &&
        window.location.pathname !== "/billing" &&
        window.location.pathname !== "/miniwebsite"
      ) {
        setRedirected(true);
        window.location.replace("./home");
      }
    }
  }, [subscriptionState, redirected]);

  return (
    <>
      {showAlert && (
        <div
          className={`${styles.Alerts} ${styles["bg-sky-blue-500"]} Alerts text-white px-6 py-4 border-0 rounded relative mb-4`}
        >
          <span className="text-xl inline-block mr-5 align-middle">
            <WarningErrorIcon />
          </span>
          <span
            className={`inline-block align-middle mr-8 ${styles["alert-text"]}`}
          >
            {isExpired ? (
              <b>
                {" "}
                Your account is on Free plan. Upgrade to use digital menu.{" "}
                <a
                  className={`underline ${styles["alert-text"]}`}
                  href="/billing"
                  target="_blank"
                >
                  Upgrade Now
                </a>
                .
              </b>
            ) : (
              <b>
                {" "}
                Your account is on Pro plan free trial until{" "}
                {formatTrialEndDate(subscription.trialEnd)}.{" "}
                <a
                  className={`underline ${styles["alert-text"]}`}
                  href="/billing"
                  target="_blank"
                >
                  Upgrade Now
                </a>
                .
              </b>
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default Alert;
