import React, { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { ChevronDown } from "assets/icons/ChevronDown";
import { Value } from "sass";

const AppCombobox = ({
  data,
  selectDefault,
  onSelected,
}: {
  data: any;
  selectDefault: any;
  onSelected: (data: any) => void;
}) => {
  const [selected, setSelected] = useState<any>();
  const [query, setQuery] = useState("");
  const filteredData =
    query === ""
      ? data
      : data.filter((item: any) =>
          item?.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const selectDefaultData = data?.filter(
    (item: any) => item?.value == selectDefault
  )[0];
  useEffect(() => {
    setSelected(selectDefaultData);
  }, [selectDefault, data]);

  const onchange = (data: Value) => {
    onSelected(data);
    setSelected(data);
  };

  return (
    <div className="rounded-md border border-neutral-30 text-base mt-2 min-h-[41px]">
      <Combobox value={selected} onChange={onchange}>
        <div className="relative mt-0.5">
          <div>
            <Combobox.Button className="flex items-center pr-2 w-full focus:outline-0 h-10">
              <Combobox.Input
                className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-0 ${selected?.props?.className}`}
                displayValue={(data: any) => data.name}
                onChange={(event) => setQuery(event.target.value)}
              />
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute z-[12] mt-1 p-3 max-h-60 w-full overflow-auto rounded-md bg-white text-base shadow-dropdown focus:outline-none sm:text-sm">
              {filteredData.map((item: any, index: number) => (
                <Combobox.Option
                  key={index}
                  className={({ active }) =>
                    `relative cursor-default select-none!font-normal text-left cursor-pointer text-sm hover:bg-neutral-10 w-full p-2 rounded-md transition${
                      active ? "text-primary-light" : "text-gray-900"
                    } ${item.props?.className}`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected
                            ? "text-primary-light font-medium"
                            : "font-normal"
                        }`}
                      >
                        {item?.label || item?.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"></span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export { AppCombobox };
