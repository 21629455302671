import React, { useState, ReactNode, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosRequestConfig } from "axios";
import AuthContext from "./AuthContext";
import { UserType } from "types/User.type";
import { NormalResponseError } from "types/Common.type";
import AuthService from "services/auth.service";
import axiosInstance from "services/restful.service";
import { AUTH_CONST } from "constant/auth.const";
import { useAppDispatch } from "store/hooks";

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<UserType>();
  const [currentUser, setCurrentUser] = useState<UserType | undefined>(
    undefined
  );
  const [error, setError] = useState<NormalResponseError | null | undefined>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();

  const axiosInstanceInterceptorWithCookie = async (
    config: AxiosRequestConfig
  ) => {
    let user: any = localStorage.getItem("user");
    let token;
    if (user) {
      user = JSON.parse(user);
      var token_expiry = localStorage.getItem("token_expiry");
      let keep_logged = localStorage.getItem("keep_logged");
      if (
        keep_logged &&
        token_expiry &&
        Date.now() > +token_expiry &&
        user?.refreshToken
      ) {
        var res = await AuthService.refreshToken(user.refreshToken);
        if (res.ok) {
          var response = await res.json();
          user.accessToken = response.accessToken;
          localStorage.setItem("user", JSON.stringify(user));
          setExpiryTime(user.expiresIn);
        }
      }
      token = user.accessToken;
    }
    const newHeaders = { ...config } as any;
    if (token) {
      newHeaders.headers["Authorization"] = "Bearer " + token;
    }
    return newHeaders;
  };

  useEffect(() => {
    setError(null);
    const user = localStorage.getItem("user");
    const currentUser = localStorage.getItem("currentUser");

    if (user) {
      setUser(JSON.parse(user));
    }

    if (currentUser) {
      setCurrentUser(JSON.parse(currentUser));
    }
    setLoadingInitial(false);
  }, []);

  React.useEffect(() => {
    let idInterceptor;
    if (user?.accessToken) {
      idInterceptor = axiosInstance.interceptors.request.use(
        axiosInstanceInterceptorWithCookie,
        function (error: any) {
          return Promise.reject(error);
        }
      );
    } else {
      if (idInterceptor) {
        axiosInstance.interceptors.request.eject(idInterceptor);
      }
    }
    axiosInstance.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: AxiosError<any>) {
        const { response } = error;
        if (response) {
          const { data, status } = response;
          if (
            (status === 401 || data?.error?.code === 401) &&
            localStorage.getItem("user")
          ) {
            localStorage.removeItem("user");
            localStorage.removeItem("currentUser");
            setUser(undefined);
            logout();
          }
          if (status === 404 || data?.error?.code === 404) {
            localStorage.clear();
            navigate("/404");
          }
          if (
            (status === 403 || data?.error?.code === 403) &&
            data?.error?.name === AUTH_CONST.UNAUTHORIZED
          ) {
            setError(data);
          }
        }
        return Promise.reject(error);
      }
    );
  }, [user?.accessToken]);

  const login = React.useCallback(
    async (username: string, password: string, isSignUp?: boolean) => {
      try {
        setLoading(true);
        setError(null);
        const response = await AuthService.loginService({ username, password });
        if (response?.data) {
          checkLogin(response, isSignUp);
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          setError(error.response.data.errors || error.response.data.error);
        } else {
          setError(() => ({
            error: {
              code: 0,
              description: "An error occurred during login.",
              body_params: [],
              name: "UnknownError",
            },
          }));
        }
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const refreshToken = (refreshToken: string) => {
    try {
      setLoading(true);
      setError(null);
      AuthService.refreshToken({ refreshToken })
        .then(async (response: any) => {
          if (response?.data) {
            checkLogin(response);
          }
        })
        .catch((error: any) => {
          setError(
            error?.response?.data?.errors || error?.response?.data?.error
          );
        })
        .finally(() => setLoading(false));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getCurrentUser = () => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    if (currentUser && (user?.isAdmin || user?.isPartner)) return currentUser;
    else return user;
  };

  const getCurrentUserId = () => {
    return getCurrentUser().userId;
  };

  const getLoginUser = () => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
    }
    return user;
  };

  const onCurrentUserChanged = (user: UserType | undefined) => {
    localStorage.setItem("currentUser", JSON.stringify(user));
    window.location.href = "/";
  };

  const logout = () => {
    let keep_logged = localStorage.getItem("keep_logged");
    let user: any = localStorage.getItem("user");
    let refreshToken = "";
    if (keep_logged && user) {
      keep_logged = JSON.parse(keep_logged);
      user = JSON.parse(user);
      refreshToken = user?.refreshToken;
    }
    localStorage.clear();

    setUser(undefined);
    setCurrentUser(undefined);
    navigate("/login");
  };

  const keepLogin = () => {
    let keep_logged = localStorage.getItem("keep_logged");
    let user: any = localStorage.getItem("user");
    let refreshToken = "";
    if (keep_logged && user) {
      keep_logged = JSON.parse(keep_logged);
      user = JSON.parse(user);
      refreshToken = user?.refreshToken;
    }
    if (keep_logged) {
      localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
    }
  };

  const update = (value: UserType) => {
    let user: any = localStorage.getItem("user");
    user = JSON.parse(user);
    user = {  ...user, ...value };
    localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  };

  const setExpiryTime = (seconds: number) => {
    var tokenExpiryTime = new Date().setSeconds(seconds).toString();
    localStorage.setItem("token_expiry", tokenExpiryTime);
  };

  const checkLogin = async (response: any, isSignUp?: boolean) => {
    let config = {
      headers: { Authorization: "Bearer " + response?.data.accessToken },
    };

    const responseProfile = await AuthService.getProfileByUserId(
      response.data.userId,
      config
    );
    localStorage.setItem(
      "user",
      JSON.stringify({ ...response.data, ...responseProfile.data })
    );
    setExpiryTime(response.data.expiresIn);
    keepLogin();
    setUser({ ...response.data, ...responseProfile.data });
    window.usetifulTags = { userId: response.data.userId };
    localStorage.removeItem("currentUser");

    if (isSignUp) {
      return navigate("/setup-menu");
    }
    navigate("/");
  };

  const isStripeAccountCompletedOnboarding = () => {
    return user?.paymentAccountStatus === "CompleteOnboarding";
  };

  const isStripeAccountConnected = () => {
    return user?.paymentAccountStatus === "Complete";
  };

  const isStripeAccountRestricted = () => {
    return user?.paymentAccountStatus === "Restricted";
  }

  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      currentUser,
      login,
      logout,
      refreshToken,
      setError,
      update,
      onCurrentUserChanged,
      getCurrentUser,
      getCurrentUserId,
      getLoginUser,
      isStripeAccountConnected,
      isStripeAccountCompletedOnboarding,
      isStripeAccountRestricted
    }),
    [user, loading, error, login]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
