import React from "react"
export const Logo = () => {
    return (
        <svg width="244" height="55" viewBox="0 0 244 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M113.44 42.9651C112.955 42.9651 112.49 42.7725 112.147 42.4297C111.805 42.0869 111.612 41.6219 111.612 41.1371V26.5586C111.612 20.261 106.192 15.1425 99.5378 15.1425C92.8838 15.1425 87.4637 20.261 87.4637 26.5586V41.1828C87.4637 41.6676 87.2711 42.1326 86.9283 42.4754C86.5854 42.8182 86.1205 43.0108 85.6356 43.0108C85.1508 43.0108 84.6859 42.8182 84.343 42.4754C84.0002 42.1326 83.8076 41.6676 83.8076 41.1828V26.5586C83.8076 18.2502 90.8638 11.4865 99.5378 11.4865C108.212 11.4865 115.268 18.2502 115.268 26.5586V41.1828C115.256 41.6597 115.058 42.113 114.717 42.446C114.375 42.779 113.917 42.9653 113.44 42.9651Z" fill="#185D66" />
            <path d="M138.457 42.9651C129.783 42.9651 122.727 36.2014 122.727 27.8839V13.2962C122.727 12.8114 122.919 12.3464 123.262 12.0036C123.605 11.6608 124.07 11.4682 124.555 11.4682C125.039 11.4682 125.504 11.6608 125.847 12.0036C126.19 12.3464 126.383 12.8114 126.383 13.2962V27.9204C126.383 34.218 131.803 39.3456 138.457 39.3456C145.111 39.3456 150.531 34.218 150.531 27.9204V13.2962C150.531 12.8114 150.723 12.3464 151.066 12.0036C151.409 11.6608 151.874 11.4682 152.359 11.4682C152.844 11.4682 153.309 11.6608 153.652 12.0036C153.994 12.3464 154.187 12.8114 154.187 13.2962V27.9204C154.187 36.2014 147.131 42.9651 138.457 42.9651Z" fill="#185D66" />
            <path d="M243.961 25.7268V13.2962C243.961 12.8114 243.769 12.3464 243.426 12.0036C243.083 11.6608 242.618 11.4682 242.133 11.4682C241.648 11.4682 241.184 11.6608 240.841 12.0036C240.498 12.3464 240.305 12.8114 240.305 13.2962V27.8565C240.305 34.1358 234.885 39.2451 228.231 39.2451C221.577 39.2451 216.157 34.1358 216.157 27.8565V13.2962C216.157 12.8114 215.964 12.3464 215.622 12.0036C215.279 11.6608 214.814 11.4682 214.329 11.4682C213.844 11.4682 213.379 11.6608 213.036 12.0036C212.694 12.3464 212.501 12.8114 212.501 13.2962V27.8565C212.501 36.1557 219.557 42.9012 228.231 42.9012C230.514 42.9116 232.773 42.4334 234.855 41.4985C236.938 40.5636 238.796 39.1938 240.305 37.481V39.8666C240.305 45.9174 234.894 50.8348 228.231 50.8348C225.328 50.8554 222.505 49.8885 220.224 48.0928C219.845 47.8121 219.373 47.6888 218.905 47.7487C218.437 47.8085 218.01 48.0468 217.714 48.4138C217.418 48.7808 217.275 49.2481 217.315 49.718C217.356 50.188 217.576 50.6241 217.93 50.9354C220.854 53.2707 224.489 54.535 228.231 54.5183C236.905 54.5183 243.961 47.9557 243.961 39.894V25.7268Z" fill="#30A9A6" />
            <path d="M177.174 42.9012C174.873 42.9015 172.601 42.3928 170.52 41.4113C167.79 40.1276 165.485 38.0886 163.878 35.5357C162.271 32.9828 161.43 30.0229 161.453 27.0064V2.32803C161.453 1.84321 161.645 1.37824 161.988 1.03542C162.331 0.692596 162.796 0.5 163.281 0.5C163.765 0.5 164.23 0.692596 164.573 1.03542C164.916 1.37824 165.109 1.84321 165.109 2.32803V27.0064C165.081 29.1776 165.638 31.3162 166.723 33.1973C167.807 35.0784 169.379 36.6325 171.272 37.6961C173.165 38.7598 175.309 39.2937 177.48 39.2418C179.651 39.1898 181.767 38.5539 183.607 37.4009C185.447 36.2479 186.942 34.6204 187.936 32.6896C188.929 30.7588 189.384 28.596 189.252 26.4286C189.12 24.2613 188.407 22.1695 187.187 20.3733C185.967 18.577 184.286 17.1427 182.32 16.2211C180.856 15.5271 179.267 15.1377 177.649 15.0764C176.031 15.0151 174.417 15.2833 172.905 15.8646C172.681 15.9498 172.442 15.99 172.202 15.9828C171.962 15.9757 171.726 15.9213 171.507 15.8229C171.288 15.7244 171.09 15.5838 170.925 15.409C170.761 15.2343 170.632 15.0288 170.547 14.8043C170.462 14.5799 170.422 14.3408 170.429 14.1009C170.436 13.8609 170.49 13.6246 170.589 13.4057C170.687 13.1867 170.828 12.9893 171.003 12.8246C171.177 12.66 171.383 12.5314 171.607 12.4462C173.765 11.6274 176.077 11.2932 178.378 11.4674C180.68 11.6415 182.915 12.3197 184.925 13.4538C186.935 14.588 188.671 16.1503 190.01 18.0302C191.349 19.9101 192.259 22.0615 192.674 24.332C193.089 26.6024 192.999 28.9364 192.412 31.1684C191.824 33.4004 190.753 35.4759 189.274 37.2478C187.795 39.0197 185.944 40.4446 183.853 41.4216C181.762 42.3986 179.482 42.9037 177.174 42.9012Z" fill="#30A9A6" />
            <path d="M77.1714 24.0816C77.1714 24.0816 77.1714 23.9993 77.1714 23.9536C76.4373 20.5022 74.5646 17.3976 71.8542 15.1382C69.1437 12.8788 65.7527 11.5958 62.2255 11.4951C58.6983 11.3945 55.2396 12.482 52.4046 14.5831C49.5697 16.6842 47.5231 19.6769 46.5933 23.0809C45.6636 26.4848 45.9046 30.1025 47.2777 33.353C48.6509 36.6035 51.0765 39.2983 54.1651 41.0047C57.2537 42.7111 60.8261 43.3301 64.3088 42.7624C67.7915 42.1946 70.9823 40.473 73.3691 37.8741C73.6975 37.5165 73.8705 37.0431 73.8499 36.558C73.8294 36.0729 73.6169 35.6159 73.2594 35.2874C72.9018 34.9589 72.4284 34.786 71.9433 34.8065C71.4582 34.8271 71.0012 35.0395 70.6727 35.3971C68.8995 37.3238 66.5479 38.6218 63.9726 39.0955C61.3973 39.5691 58.7378 39.1927 56.395 38.023C54.0523 36.8533 52.1533 34.9537 50.9844 32.6106C49.8154 30.2675 49.4399 27.6078 49.9143 25.0327C50.3888 22.4575 51.6876 20.1064 53.6148 18.3337C55.5421 16.5611 57.9934 15.463 60.5992 15.2051C63.205 14.9471 65.824 15.5433 68.0614 16.9037C70.2988 18.2641 72.0333 20.3149 73.0035 22.7471H56.469C55.9841 22.7471 55.5192 22.9397 55.1763 23.2825C54.8335 23.6254 54.6409 24.0903 54.6409 24.5751C54.6409 25.06 54.8335 25.5249 55.1763 25.8678C55.5192 26.2106 55.9841 26.4032 56.469 26.4032H75.4165C75.9013 26.4032 76.3662 26.2106 76.7091 25.8678C77.0519 25.5249 77.2445 25.06 77.2445 24.5751C77.2428 24.4081 77.2182 24.242 77.1714 24.0816Z" fill="#185D66" />
            <path d="M202.117 42.9012C201.632 42.9012 201.167 42.7086 200.824 42.3657C200.482 42.0229 200.289 41.558 200.289 41.0731V2.32803C200.289 1.84321 200.482 1.37824 200.824 1.03542C201.167 0.692596 201.632 0.5 202.117 0.5C202.602 0.5 203.067 0.692596 203.41 1.03542C203.753 1.37824 203.945 1.84321 203.945 2.32803V41.0731C203.945 41.558 203.753 42.0229 203.41 42.3657C203.067 42.7086 202.602 42.9012 202.117 42.9012Z" fill="#30A9A6" />
            <path d="M28.8387 11.4682C27.0611 11.4686 25.3111 11.9075 23.7438 12.7462C22.1766 13.5848 20.8404 14.7972 19.8539 16.2759C18.5624 14.3459 16.6843 12.8826 14.4972 12.1021C12.3101 11.3216 9.92988 11.2654 7.70832 11.9417C5.48676 12.618 3.54167 13.991 2.16044 15.8578C0.779213 17.7246 0.0351168 19.9862 0.0380948 22.3084V41.1371C0.0380948 41.6219 0.23069 42.0869 0.573512 42.4297C0.916333 42.7725 1.3813 42.9651 1.86612 42.9651C2.35095 42.9651 2.81591 42.7725 3.15873 42.4297C3.50155 42.0869 3.69415 41.6219 3.69415 41.1371V22.3084C3.69415 20.4079 4.44912 18.5852 5.79298 17.2413C7.13684 15.8975 8.95951 15.1425 10.86 15.1425C12.7605 15.1425 14.5832 15.8975 15.9271 17.2413C17.2709 18.5852 18.0259 20.4079 18.0259 22.3084V41.1371C18.0259 41.6219 18.2185 42.0869 18.5613 42.4297C18.9041 42.7725 19.3691 42.9651 19.8539 42.9651C20.3387 42.9651 20.8037 42.7725 21.1465 42.4297C21.4893 42.0869 21.6819 41.6219 21.6819 41.1371V22.3084C21.6476 21.3464 21.8074 20.3874 22.1518 19.4885C22.4962 18.5897 23.0182 17.7694 23.6865 17.0768C24.3549 16.3841 25.156 15.8331 26.042 15.4568C26.928 15.0806 27.8807 14.8866 28.8432 14.8866C29.8058 14.8866 30.7585 15.0806 31.6445 15.4568C32.5305 15.8331 33.3316 16.3841 34 17.0768C34.6683 17.7694 35.1903 18.5897 35.5347 19.4885C35.8791 20.3874 36.0389 21.3464 36.0045 22.3084V41.1371C36.0045 41.6219 36.1971 42.0869 36.54 42.4297C36.8828 42.7725 37.3477 42.9651 37.8326 42.9651C38.3174 42.9651 38.7824 42.7725 39.1252 42.4297C39.468 42.0869 39.6606 41.6219 39.6606 41.1371V22.3084C39.6618 20.886 39.3828 19.4774 38.8396 18.1628C38.2964 16.8483 37.4996 15.6536 36.4947 14.647C35.4898 13.6404 34.2965 12.8416 32.9828 12.2961C31.6692 11.7507 30.261 11.4694 28.8387 11.4682Z" fill="#185D66" />
        </svg>

    )
}