import React from "react";

const Label = ({
  name,
  isRequired,
  className,
}: {
  name: string;
  isRequired?: boolean;
  className?: string;
}) => {
  return (
    <>
      <label className={`text-base text-neutral-80 font-medium ${className}`}>
        {name}
        {isRequired && (
          <span className="text-default-error ml-1 inline-block">*</span>
        )}
      </label>
    </>
  );
};

export { Label };
