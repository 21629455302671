import React, { createContext, useState } from "react";

export const DataServiceContext = createContext<any>(undefined);

const DataService = ({ children }: { children?: React.ReactNode }) => {
  const [data, setData] = useState(null);

  const storeData = (rowData: any) => {
    setData(rowData);
  };

  return (
    <DataServiceContext.Provider value={{ data, storeData }}>
      {children}
    </DataServiceContext.Provider>
  );
};
export { DataService };
