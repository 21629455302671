type CartItem = {
  id: any;
  amount: any;
  product: any;
  currencySign: any;
  placeId:any;
}

type CartState = {
  cart: CartItem[];
  totalItems: number;
  totalAmount: number;
  currencySign: any,
}

type CartAction =
  | { type: 'ADD_TO_CART', payload: { id?: any, amount?: any, product?: any, currencySign?: any ,placeId?:string} }

const cartReducer = (state: CartState, action: CartAction): CartState => {

  switch (action.type) {
    case 'ADD_TO_CART':
      
      const { id, amount, product, currencySign, placeId } = action.payload;
      const existingCartItem = state?.cart.find(item => item.id === product.id);
      if (existingCartItem) {
        const updatedCartItems = state.cart.map(item => {
          if (item.id === product.id) {
            return { ...item, amount:  amount, currencySign: currencySign , placeId:placeId};
          } else {
            return item;
          }
        });

        return {
          ...state,
          cart: updatedCartItems,
          totalItems: amount,
          currencySign: currencySign || existingCartItem.currencySign,
        };
      } else {
        const newCartItem = { ...product, amount, currencySign ,placeId};
        return {
          ...state,
          cart: [...state.cart, newCartItem],
          totalItems: amount,
          currencySign: currencySign,
        };
      }

    default:
      return state;
  }
};

export default cartReducer;

