import React from "react"
export const TrashIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.33333 15.4998C7.8731 15.4998 7.5 15.1267 7.5 14.6665L7.5 8.83317C7.5 8.37293 7.8731 7.99984 8.33333 7.99984C8.79357 7.99984 9.16667 8.37293 9.16667 8.83317L9.16667 14.6665C9.16667 15.1267 8.79357 15.4998 8.33333 15.4998Z" fill="currentColor" />
            <path d="M10.8333 14.6665C10.8333 15.1267 11.2064 15.4998 11.6667 15.4998C12.1269 15.4998 12.5 15.1267 12.5 14.6665V8.83317C12.5 8.37293 12.1269 7.99984 11.6667 7.99984C11.2064 7.99984 10.8333 8.37293 10.8333 8.83317V14.6665Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.79412 3.49003L6.16667 4.6665H3.33333C2.8731 4.6665 2.5 5.0396 2.5 5.49984C2.5 5.96007 2.8731 6.33317 3.33333 6.33317H3.38053L4.06391 18.0484C4.08962 18.489 4.45445 18.8332 4.89583 18.8332H15.1042C15.5456 18.8332 15.9104 18.489 15.9361 18.0484L16.6195 6.33317H16.6667C17.1269 6.33317 17.5 5.96007 17.5 5.49984C17.5 5.0396 17.1269 4.6665 16.6667 4.6665H13.8333L13.2059 3.49003C12.7714 2.67537 11.9233 2.1665 11 2.1665H9C8.07672 2.1665 7.2286 2.67537 6.79412 3.49003ZM9 3.83317C8.69224 3.83317 8.40953 4.00279 8.26471 4.27435L8.05556 4.6665H11.9444L11.7353 4.27435C11.5905 4.00279 11.3078 3.83317 11 3.83317H9ZM14.95 6.33317H5.05003L5.68197 17.1665H14.318L14.95 6.33317Z" fill="currentColor" />
        </svg>

    )
}